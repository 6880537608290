import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { MatiereState } from '../../interface/GeneralStateInterface';

const URI = API_URL+"gestiondescursus/matieres/"
const URI2 = API_URL+"gestiondescursus/filieres/"

const initialState: MatiereState = {
    matieres: [],
    matiere: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setMatiere = createAsyncThunk<any, Object>(
    'matiere/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)


export const setMatiereToFiliere = createAsyncThunk<any, Object>(
    'matiere/add-to-filiere',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}${content.id}/ajouter_matiere/`,
                content: content.data,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const removeMatiereToFiliere = createAsyncThunk<any, Object>(
    'matiere/remove-to-filiere',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}${content.id}/remove_matiere/`,
                content: content.data,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getMatieres = createAsyncThunk<any, Object>(
    'matiere/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getMatiere = createAsyncThunk<any, Object>(
    'matiere/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateMatiere = createAsyncThunk<any, Object>(
    'matiere/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteMatiere = createAsyncThunk<any, Object>(
    'matiere/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const matiereSlice = createSlice({
    name: 'matiere',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMatieres.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMatieres.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.matieres = action.payload
            })
            .addCase(getMatieres.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getMatiere.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMatiere.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.matiere = action.payload
            })
            .addCase(getMatiere.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setMatiere.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setMatiere.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.matiere = action.payload
                state.matieres = [action.payload, ...state.matieres]
            })
            .addCase(setMatiere.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })


            .addCase(setMatiereToFiliere.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setMatiereToFiliere.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                // state.matieres = [action.payload, ...state.matieres]
            })
            .addCase(setMatiereToFiliere.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateMatiere.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateMatiere.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.matieres = [action.payload, ...state.matieres.filter((matiere: any) => matiere?.id !== action.payload?.id)]
                state.matiere = action.payload
            })
            .addCase(updateMatiere.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteMatiere.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteMatiere.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.matieres = state.matieres.filter((matiere: any) => matiere?.id !== action.payload?.id)
            })
            .addCase(deleteMatiere.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

            .addCase(removeMatiereToFiliere.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(removeMatiereToFiliere.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                // state.matieres = state.matieres.filter((matiere: any) => matiere?.id !== action.payload?.id)
            })
            .addCase(removeMatiereToFiliere.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = matiereSlice.actions
export default matiereSlice.reducer