import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext } from './context/ThemeContext';
import useInternetConnection from './hooks/useInternetConnection';
import { NotFoundError } from './view/assets/illustration';
import { AuthContext } from './context/AuthContext';
import { WebRouter } from './view/routes';


function App() {
  const {
    theme,
    colorPalette,
    defaultDark,
    defaultTheme,
  } = useContext(ThemeContext);

  const [isConnected, setIsConnected] = useState<boolean>(true)

  const isOnlineConnected = useInternetConnection();

  const [isDesktopStatut, setIsDesktopStatut] = useState<boolean>(true)

  const isDesktop = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return !/mobile|android|iphone|ipad|tablet|blackberry|phone/.test(userAgent);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsConnected(window.navigator.onLine);
    }, 2000); 

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  useEffect(() => {
    setIsDesktopStatut(isDesktop())
  }, [isDesktop])

  return (
    <>
      {
        isDesktopStatut ? (
          <AuthContext>
            <div 
              className="kui-app"
              data-theme={defaultTheme === "default" ? (defaultDark ? "dark" : "light") : theme}
              data-palette={colorPalette}
            >
              <WebRouter/>
              {
                !isConnected ? (
                  <div className={`kui-offline-alert`}>
                    <strong>Vous n'êtes pas connecté à internet!!</strong>
                    <p>Veuillez verifier l'etat de votre connexion à internet et <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>réactualiser...</button></p>
                  </div>
                ) : (
                  !isOnlineConnected ? (
                    <div className={`kui-offline-alert`}>
                      <strong>Vous n'êtes pas connecté à internet!!</strong>
                      <p>Veuillez verifier l'etat de votre connexion à internet et <button type='button' onClick={() => window.location.reload()} style={{textDecoration: "underline"}}>réactualiser...</button></p>
                    </div>
                  ) : null
                )
              }
            </div>
          </AuthContext>
        ) : (
          <div 
            className="kui-app"
            data-theme={defaultTheme === "default" ? (defaultDark ? "dark" : "light") : theme}
            data-palette={colorPalette}
          >
            <div className='kui-page-error'>
              <div className='kui-page-error__illustration'>
                <NotFoundError loop={true} autoplay={true} size={{
                  height: 180,
                  width: 180
                }}/>
              </div>
              <div className='kui-page-error_details'>
                <p>Oupps!!</p>
                <h1>Plateforme prevue uniquement pour une utilisation sur ordinateur</h1>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default App;
