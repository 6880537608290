
import { AccountPage, AdminPage, AdminsPage, CustomPage, CyclePage, DepartmentPage, ErrorPage, FilierePage, FilieresPage, HomePage, LoginPage, NotifPage } from "../../../pages";
import { AnneesPage, SessionInscriptionsPage } from "../../../pages/src/school_year";
import { links } from "./links";


export const routes = [
    {
        path: links.home,
        Component: HomePage
    },
    {
        path: links.login,
        Component: LoginPage
    },
    {
        path: links.notif,
        Component: NotifPage
    },
    {
        path: links.setting,
        Component: CustomPage
    },
    {
        path: links.account,
        Component: AccountPage
    },
    {
        path: links.admins,
        Component: AdminsPage
    },
    {
        path: links.admin + "/:slug",
        Component: AdminPage
    },
    {
        path: links.cycles,
        Component: CyclePage
    },
    {
        path: links.departments,
        Component: DepartmentPage
    },
    {
        path: links.filieres,
        Component: FilieresPage
    },
    {
        path: links.filiere + "/:slug",
        Component: FilierePage
    },
    {
        path: links.school_years,
        Component: AnneesPage
    },
    {
        path: links.subscriptions_sessions,
        Component: SessionInscriptionsPage
    },
    {
        path: links.error,
        Component: ErrorPage
    },
]