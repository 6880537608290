import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { NiveauState } from '../../interface/GeneralStateInterface';

const URI = API_URL+"gestiondescursus/niveaux/"

const initialState: NiveauState = {
    niveaux: [],
    niveau: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setNiveau = createAsyncThunk<any, Object>(
    'niveau/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getNiveaux = createAsyncThunk<any, Object>(
    'niveau/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getNiveau = createAsyncThunk<any, Object>(
    'niveau/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateNiveau = createAsyncThunk<any, Object>(
    'niveau/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteNiveau = createAsyncThunk<any, Object>(
    'niveau/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const niveauSlice = createSlice({
    name: 'niveau',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getNiveaux.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNiveaux.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.niveaux = action.payload
            })
            .addCase(getNiveaux.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getNiveau.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getNiveau.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.niveau = action.payload
            })
            .addCase(getNiveau.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setNiveau.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setNiveau.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.niveau = action.payload
                state.niveaux = [action.payload, ...state.niveaux]
            })
            .addCase(setNiveau.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateNiveau.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateNiveau.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.niveaux = [action.payload, ...state.niveaux.filter((niveau: any) => niveau?.id !== action.payload?.id)]
                state.niveau = action.payload
            })
            .addCase(updateNiveau.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteNiveau.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteNiveau.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.niveaux = state.niveaux.filter((niveau: any) => niveau?.id !== action.payload?.id)
            })
            .addCase(deleteNiveau.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = niveauSlice.actions
export default niveauSlice.reducer