import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteUser, getUsers, recoverPasswordUser, reset, setUser } from '../../../../../app/redux/features/user/user.slice';
import { links } from '../../../../routes';
import { API_URL2, createInitial, sexeOptions } from '../../../../../app/utils';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { MainLayout } from '../../../../layout';


const AddModal = ({fnc}:{ fnc: (val: boolean) => void | any}) => {

  let navigate = useNavigate()

  const dispatch = useAppDispatch();
  const { isCreateSuccess, isCreateLoading, isCreateError, user, message } = useAppSelector((state) => state.user);
  
  const [data, setData] = useState<any>({
    email: "",
    password: "123456789",
    confirm_password: "123456789",
    profile: {
        nom: "",
        prenom: "",
        ville: "",
        telephone: "",
        adresse: "",
        pays_de_residence: "",
        boite_postale: "",
        date_de_naissance: null,
        lieu_de_naissance: "",
        pays_de_naissance: "",
        nationalite: "",
        sexe: ""
    },
    is_admin: true,
  })


  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.profile?.nom){
        setError({
            field: "nom",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.email){
    setError({
        field: "email",
        message: "Veuillez renseigner ce champs"
    })
    return
    }

    if(!data.profile?.prenom){
        setError({
            field: "prenom",
            message: "Veuillez renseigner ce champs"
        })
        return
    }

    if(!data.profile?.sexe){
        setError({
            field: "sexe",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    
    setError(null)
    setCloseM(true)
    dispatch(setUser(data))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }


  return (
    <Modal size={'is--md'} withClose={true} id={'edit-category'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un administrateur</h2>
      </div>
      {
        closeM && isCreateSuccess && user ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
              <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
            
              <div className='kui-modal-box-statut__container'>
                <h1>Le compte a bien été avec succès</h1>
                <p>
                  Le compte de l'administrateur <strong>{user?.firstname + " " +user?.lastname?.toUpperCase()}</strong> a é créé
                </p>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='kui-modal-box__content'>
              {
                closeM && isCreateError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <ModalForm tabsField={[
                {
                  field: "textfield",
                  field_type: "text",
                  field_require: true,
                  value: data.profile?.nom,
                  label: "Nom",
                  id: "nom",
                  onChange: (value: any) => setData({...data, profile: {...data.profile, nom: value}}),
                  error: error,
                  placeholder: "Ex: Doe",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "text",
                  field_require: true,
                  value: data.profile?.prenom,
                  label: "Prenom(s)",
                  id: "prenom",
                  onChange: (value: any) => setData({...data, profile: {...data.profile, prenom: value}}),
                  error: error,
                  placeholder: "Ex: John",
                  isShow: true
                },
                {
                  field: "selectfield",
                  field_type: "no-multiple",
                  field_require: true,
                  value: data.profile.sexe,
                  label: "Sexe",
                  id: "sexe",
                  onChange: (value: any) => setData({...data, profile: {...data.profile, sexe: value}}),
                  error: error,
                  tabs: sexeOptions,
                  placeholder: "Ex: Masculin ou feminin",
                  isShow: true
                },
                {
                  field: "selectfield",
                  field_type: "no-multiple",
                  field_require: false,
                  value: data.profile?.nationalite,
                  label: "Nationalité",
                  id: "nationalite",
                  onChange: (val: string) => setData({...data, profile: {...data.profile, nationalite: val}}),
                  error: error,
                  tabs: sexeOptions,
                  placeholder: "Ex: Masculin ou feminin",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "date",
                  field_require: false,
                  value: data.profile?.date_de_naissance,
                  label: "Date de naissance ",
                  id: "date_de_naissance",
                  onChange: (val: string) => setData({...data, profile: {...data.profile, date_de_naissance: val}}),
                  error: error,
                  placeholder: "Entrer la date de naissance",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "email",
                  field_require: true,
                  value: data.email,
                  label: "Email",
                  id: "email",
                  onChange: (val: any) => setData({...data, email: val}),
                  error: error,
                  placeholder: "Ex: john@doe.com",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "phone",
                  field_require: false,
                  value: data?.profile?.telephone,
                  label: "Telephone",
                  id: "telephone",
                  onChange: (val: any) => setData({...data, profile: {...data.profile, telephone: val}}),
                  error: error,
                  placeholder: "Ex: 0022671093456",
                  isShow: true
                },
              ]}/>
            </div>
          </>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: closeM && isCreateSuccess ? false : true
          },
          {
            icon: "pi-eye-line", 
            type: "button", 
            label: "Voir le profile", 
            style:"is--primary", 
            active: true,
            onClick: () => navigate(links.admin + '/' + user?.slug),
            show: closeM && isCreateSuccess ? true : false
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

// export const RecoverPasswordModal = ({item, fnc}:{ item?: any, fnc: (val: boolean) => void | any}) => {

//   const dispatch = useAppDispatch();
//   const { isEditError, isEditSuccess, isEditLoading, message } = useAppSelector((state) => state.user);
//   const [closeM, setCloseM] = useState<boolean>(false)
//   const closeModal = () => {
//     fnc(false)
//     setCloseM(false)
//   }
//   return (
//     <Modal size={'is--sm'} withClose={!isEditLoading} id={'delete-category'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
//       <div className='kui-modal-box__header'>
//         <h2>Reinitialisation du mot de passe d'un administrateur</h2>
//       </div>
//       {
//         closeM && isEditSuccess ? (
//           <div className='kui-modal-box__content'>
//             <div className='kui-modal-box__status kui-modal-box-statut is--success'>
//               <div className='kui-modal-box-statut__illustration'>
//               <AddSuccessIllu loop={true} autoplay={true} size={{
//                   height: 120,
//                   width: 120
//                 }}/>
//               </div>
            
//               <div className='kui-modal-box-statut__container'>
//                 <h1>Le mot de passe a bien été réinitialisé avec succès</h1>
//                 <p>
//                   Le mot de passe de l'administrateur <strong>{item?.firstname + " " +item?.lastname?.toUpperCase()}</strong> a été réinitialisé 
//                 </p>
//               </div>
//             </div>
//           </div>
//         ) : (
//           <div className='kui-modal-box__content'>
//             <div className={`kui-modal-box__status kui-modal-box-statut ${closeM && isEditError ? "is--danger" : ""} `}>
//               <div className='kui-modal-box-statut__illustration'>
//                 {
//                   closeM && isEditError ? (
//                     <FailIllu loop={true} autoplay={true} size={{
//                         height: 120,
//                         width: 120
//                       }}/>
//                   ) : (
//                     <i className='pi-safe-alert-line'></i>
//                   )
//                 }
//               </div>
//               <div className='kui-modal-box-statut__container'>
//                 <h1>
//                   {
//                     closeM && isEditError ? (
//                       "Une erreur est survenue"
//                     ) : (
//                       `Voulez-vous vraiment reinitialiser le mot de passe de ${"cet utilisateur"}?`
//                     )
//                   }
//                 </h1>
//                 {closeM && isEditError ? <p>{message}</p> : ""}
//               </div>
//             </div>
//           </div>
//         )
//       }
      
//       <ModalActions tabsActions={[
//         {
//           icon: isEditLoading ? "pi-spinner5" :"pi-safe-lock-line", 
//           type: "button", 
//           label: isEditLoading ? "Reinitialisation en cours" : (closeM && isEditError ? "Réessayer" : "Reinitialiser"), 
//           style:"is--primary", 
//           active: true,
//           onClick: () => {
//             if(item){
//               let data: any = {
//                 userId: item?._id
//               }
//               setCloseM(true)
//               dispatch(recoverPasswordUser(data))
//             }
//           },
//           show: closeM && isEditSuccess ? false : true
//         },
//         {
//           icon: "pi-close-line", 
//           type: "button", 
//           label: isEditSuccess && closeM ? "Fermer" : "Annuler", 
//           style:"is--neutral", 
//           active: !isEditLoading,
//           onClick: () => closeModal(),
//           show: true
//         },
//       ]}/>
//     </Modal>
//   )
// }

const DeleteModal = ({ item, fnc}:{ item: any, fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.user);
  
  const [closeM, setCloseM] = useState<boolean>(false)

  const closeModal = () => {
    
    fnc(false)
    setCloseM(false)
  }
  
  return (
    <Modal size={'is--sm'} withClose={!isDeleteLoading} id={'delete-category'} isOpen={true} fnc={(val: boolean) => closeModal()}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'un administrateur</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
              <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  {"l'element a bien été supprimé"}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                        height: 120,
                        width: 120
                      }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
                
              </div>
              
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {

            setCloseM(true)
            dispatch(deleteUser(item.slug))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

export const AdminsPage = () => {
  const { token, info } = useContext(UserAuthContext);
  let navigate = useNavigate()

  useEffect(() => {
    if(!token){
      navigate(links.login + `?url=${links.admins}`)
    }
  }, [token, navigate]);

  const dispatch = useAppDispatch();
  const { isLoading, isError, isSuccess, users, message } = useAppSelector((state) => state.user);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const display = searchParams.get('display')
  const n = searchParams.get('number')
  const q = searchParams.get('q')
  
  const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
  const [search, setSearch] = useState<string>(q  || "")
  const [selectItemModal, setSelectItemModal] = useState<any>(null)

  const [addModal, setAddModal] = useState<boolean>(false)
  // const [recoverModal, setRecoverModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)


  const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    navigate(links.admins+`?display=grid&number=${itemNumber}&q=${e.target.value}`)
  }

  const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemNumber(parseInt(e.target.value))
    if(parseInt(e.target.value) > 0){
      navigate(links.admins+`?display=grid&number=${parseInt(e.target.value)}${search ? `&q=${search}` : null}`)
    }
  }

  useEffect(() => {
    dispatch(getUsers({}))
    return () => {
        dispatch(reset())
    }
  }, [dispatch])

  return (
   
    <MainLayout title="Administrateurs" isMin={true}>
      <div className='kui-page is--min'>
        <div className='kui-arianne'>
          <Link className='kui-link' to={links.home}>
            <i className="pi-home-6-line"></i>
            <span>Accueil</span>
          </Link>
          <span> | </span>
          <p>Administrateurs</p>
        </div>
        {
          isLoading ? (
            <div className='kui-loader'>
              <LoaderAnimation loop={true} autoplay={true} size={{
                height: 40,
                width: 40
              }}/>
            </div>
          ) : (
            <>
              <div className='kui-page__header kui-page-header'>
                <h2>Administrateurs</h2>
              </div>
              <div className='kui-page-list'>
                <div className='kui-page-list__actions kui-page-list-actions'>
                  <div className='kui-page-list-actions-display'>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.admins+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-list-check-line"></i>
                    </button>
                    <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.admins+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                      <i className="pi-layout-grid-line"></i>
                    </button>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <div className='kui-page-list-actions-input'>
                      <span>Nombre à afficher: </span>
                      <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                    </div>
                    <div className='kui-page-list-actions-search'>
                      <i className="pi-search-line"></i>
                      <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                    </div>
                  </div>
                  <div className='kui-page-list-actions-group'>
                    <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                    
                  </div>
                </div>
                {
                  isError ? (
                    <div className='kui-page-error'>
                      <div className='kui-page-error__illustration'>
                        <NotFoundError loop={true} autoplay={true} size={{
                          height: 180,
                          width: 180
                        }}/>
                      </div>
                      <div className='kui-page-error_details'>
                        <p>Oupps! Une erreur est survenue</p>
                        <h1>{message}</h1>
                      </div>
                    </div>
                  ) : (
                    isSuccess && users? (
                      <div className='kui-page-list__container'>
                        <span className='kui-page-list__total'>{users.filter((el: any) => el?.slug !== info?.slug).filter((el: any) => el?.profile?.nom?.toLowerCase().includes(search.toLowerCase()) || el?.profile?.prenom?.toLowerCase().includes(search.toLowerCase())).length > 0 ? `${users.filter((el: any) => el?.slug !== info?.slug).filter((el: any) => el?.profile?.nom?.toLowerCase().includes(search.toLowerCase()) || el?.profile?.prenom?.toLowerCase().includes(search.toLowerCase())).length > 1 ? "elements trouvés" : "element trouvé"} ` : "Aucun element trouvé"}</span>
                        {
                          users.filter((el: any) => el?.slug !== info?.slug).filter((el: any) => el?.profile?.nom?.toLowerCase().includes(search.toLowerCase()) || el?.profile?.prenom?.toLowerCase().includes(search.toLowerCase())).length > 0 ? (
                            display === "grid" ? (
                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                {
                                  users
                                  // .filter((el: any) => el?.is_admin)
                                  .filter((el: any) => el?.slug !== info?.slug)
                                  .filter((el: any) => el?.profile?.nom?.toLowerCase().includes(search.toLowerCase()) || el?.profile?.prenom?.toLowerCase().includes(search.toLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <div className='kui-page-list-user' key={index}>
                                  <div className='kui-page-list-user__avatar'>
                                    {
                                      item?.profile?.photo_id ? (
                                        <img src={API_URL2 + item?.profile?.photo_id} alt={"avatar"} />
                                      ) : (
                                        <span>{createInitial(item?.profile?.prenom + " " +item?.profile?.nom)}</span>
                                      )
                                    }
                                    {/* <span className='notice'></span> */}
                                  </div>
                                  <div className='kui-page-list-user__container'>
                                    <div className='kui-page-list-user__name'>
                                      <strong>{item?.profile?.prenom + " " +item?.profile?.nom?.toUpperCase()}</strong>
                                      <span>{`${item?.email}`}</span>
                                    </div>
                                   
                                  </div>
                                  <div className='kui-page-list-user__actions'>
                                    <button type='button' className='kui-page-list-user__action' onClick={() => navigate(links.admin + "/" + item?.slug)}>
                                      <i className='pi-user-visible-line'></i>
                                      <span>Voir le profile</span>
                                    </button>
                                  </div>
                                  <div className='kui-page-list-user__min'>
                                    
                                    <TableOptions atype={"reduce"} tabs={[                        
                                      // {
                                      //   icon: "pi-safe-lock-line", 
                                      //   label: "Reinitialiser", 
                                      //   style:"is--neutral", 
                                      //   onClick: () => {
                                      //     setSelectItemModal(null)
                                      //     setRecoverModal(true)
                                      //     setSelectItemModal(item)
                                      //   }
                                      // },
                                      {
                                        icon: "pi-delete-2-line", 
                                        label: "Supprimer", 
                                        style:"is--danger", 
                                        onClick: () => {
                                          setSelectItemModal(null)
                                          setDeleteModal(true)
                                          setSelectItemModal(item)
                                        }
                                      },
                                    ]}/>
                                    
                                  </div>
                                </div>
                                  ))
                                }
                                
                              </Grid>
                            ) : (
                              <Table>
                                <Row rtype='header'>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Utilisateur</strong>
                                  </Column>
                                  <Column ctype={'large'}>
                                    <strong>Contacts</strong>
                                  </Column>
                                  <Column ctype={'mid'}>
                                    <strong>Statut</strong>
                                  </Column>
                                  <Column ctype={'small'}>
                                    <strong>#</strong>
                                  </Column>
                                </Row>
                                {
                                  users
                                  // .filter((el: any) => el?.is_admin)
                                  .filter((el: any) => el?.slug !== info?.slug)
                                  .filter((el: any) => el?.profile?.nom?.toLowerCase().includes(search.toLowerCase()) || el?.profile?.prenom?.toLowerCase().includes(search.toLowerCase()))
                                  .slice(0, itemNumber)
                                  .map((item: any, index: number) => (
                                    <Row key={index}>
                                      <Column ctype={'small'}>
                                        <strong>{`#${index+1}`}</strong>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <TableItem title={item?.profile?.prenom + " " +item?.profile?.nom} subtitle={``} image={item?.profile?.photo_id ? API_URL2 + item?.profile?.photo_id : ""}/>
                                      </Column>
                                      <Column ctype={'large'}>
                                        <p>
                                          <span>Email: </span> <strong>{item.email}</strong><br />
                                          <span>Telephone: </span> <strong>{item?.profile.telephone}</strong>
                                        </p>
                                      </Column>
                                      <Column ctype={'mid'}>
                                        <div>
                                          <span className='kui-chip is--success'>
                                            {item?.is_active ? "Actif" : "Inactif"}
                                          </span>
                                        </div>
                                      </Column>
                                      <Column ctype={'small'}>
                                        <TableOptions atype={"reduce"} tabs={[
                                          {
                                            icon: "pi-eye-line", 
                                            label: "Aperçu", 
                                            style:"is--neutral", 
                                            onClick: () => {
                                              navigate(links.admin + "/"+item?.slug)
                                            }
                                          },
                                          // {
                                          //   icon: "pi-safe-lock-line", 
                                          //   label: "Reinitialiser", 
                                          //   style:"is--neutral", 
                                          //   onClick: () => {
                                          //     setSelectItemModal(null)
                                          //     setRecoverModal(true)
                                          //     setSelectItemModal(item)
                                          //   }
                                          // },
                                          {
                                            icon: "pi-delete-2-line", 
                                            label: "Supprimer", 
                                            style:"is--danger", 
                                            onClick: () => {
                                              setSelectItemModal(null)
                                              setDeleteModal(true)
                                              setSelectItemModal(item)
                                            }
                                          },
                                        ]}/>
                                      </Column>
                                    </Row>
                                  ))
                                }
                                
                              </Table>
                            )
                          ) : (
                            <div className='kui-page-empty'>
                              <div className='kui-page-empty__illustration'>
                                <EmptyList loop={true} autoplay={true} size={{
                                  height: (380 / 1.3),
                                  width: (420 / 1.3)
                                }}/>
                              </div>
                              <div className='kui-page-empty__details'>
                                <h1>Aucun element trouvé</h1>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    ) : null
                  )
                }

                {addModal  ? <AddModal  fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
                {deleteModal && (selectItemModal) ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => {setDeleteModal(val || false)}}/> : null}
                {/* {recoverModal && selectItemModal ? <RecoverPasswordModal item={selectItemModal} fnc={(val?: boolean) => setRecoverModal(val || false)} /> : null} */}
        
              </div>
            </>
          )
        }
      </div>
    </MainLayout>
  )
}
