import React from 'react'
import Lottie from 'react-lottie';
import animation from '../../lottie';

interface LottieOptions {
    loop: boolean;
    autoplay: boolean;
    animationData: any;
    rendererSettings: {
      preserveAspectRatio: string;
    };
}

const LottieAnimation = ({loop, autoplay, animationData, size}: {loop: boolean, autoplay: boolean, animationData: any, size: {height: number, width: number}}) => {
  
  const defaultOptions: LottieOptions = {
    loop: loop,
    autoplay: autoplay,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return <Lottie options={defaultOptions} height={size.height} width={size.width} />;
};

export const EmptyNotification = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation1} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation1} size={size}/>
  )
}

export const SuccessOne = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation2} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation2} size={size}/>
  )
}

export const LoaderAnimation = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation3} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation3} size={size}/>
  )
}


export const EmptyList = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation4} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation4} size={size}/>
  )
}


export const AddFileSuccess = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation5} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation5} size={size}/>
  )
}

export const NotFoundError = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {animation6} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={animation6} size={size}/>
  )
}

export const FailIllu = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {fail} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={fail} size={size}/>
  )
}

export const AddSuccessIllu = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {success} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={success} size={size}/>
  )
}

export const FileAddIllu = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {file_add} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={file_add} size={size}/>
  )
}

export const FileSaveIllu = ({loop, autoplay, size} : {loop: boolean, autoplay: boolean, size: {height: number, width: number}}) => {
  const {file_save} = animation
  return (
    <LottieAnimation loop={loop} autoplay={autoplay} animationData={file_save} size={size}/>
  )
}
