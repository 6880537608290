import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FiliereState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondescursus/filieres/"
const URI2 = API_URL+"gestiondescursus/semestres_niveaux_filieres/"

const initialState: FiliereState = {
    filieres: [],
    semester_filiere: [],
    filiere: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}

export const setFiliere = createAsyncThunk<any, Object>(
    'filiere/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getFilieres = createAsyncThunk<any, Object>(
    'filiere/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getLinkBetweenFiliereSemester = createAsyncThunk<any, Object>(
    'filiere/links-with-semester',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getFiliere = createAsyncThunk<any, Object>(
    'filiere/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateFiliere = createAsyncThunk<any, Object>(
    'filiere/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const linkFilierToSemestre = createAsyncThunk<any, Object>(
    'filiere/link-to-semester',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}`,
                content: content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteFiliere = createAsyncThunk<any, Object>(
    'filiere/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const unlinkFiliereToSemestre = createAsyncThunk<any, Object>(
    'filiere/unlink-to-semester',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const filiereSlice = createSlice({
    name: 'filiere',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFilieres.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFilieres.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.filieres = action.payload
            })
            .addCase(getFilieres.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })


            .addCase(getLinkBetweenFiliereSemester.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getLinkBetweenFiliereSemester.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.semester_filiere = action.payload
            })
            .addCase(getLinkBetweenFiliereSemester.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getFiliere.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getFiliere.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.filiere = action.payload
            })
            .addCase(getFiliere.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setFiliere.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setFiliere.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.filieres = [action.payload, ...state.filieres]
            })
            .addCase(setFiliere.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateFiliere.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateFiliere.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.filieres = [action.payload, ...state.filieres.filter((filiere: any) => filiere?.id !== action.payload?.id)]
                state.filiere = action.payload
            })
            .addCase(updateFiliere.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(linkFilierToSemestre.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(linkFilierToSemestre.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.semester_filiere = [action.payload, ...state.semester_filiere]
            })
            .addCase(linkFilierToSemestre.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteFiliere.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteFiliere.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.filieres = state.filieres.filter((filiere: any) => filiere?.id !== action.payload?.id)
            })
            .addCase(deleteFiliere.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })


            .addCase(unlinkFiliereToSemestre.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(unlinkFiliereToSemestre.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.semester_filiere = state.semester_filiere.filter((unite_enseignement: any) => unite_enseignement?.id !== action.payload?.id)
            })
            .addCase(unlinkFiliereToSemestre.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = filiereSlice.actions
export default filiereSlice.reducer