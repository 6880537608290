export const links = {
    home: "/",
    error: "/*",

    // admin
    admins: "/administrateurs",
    admin: "/administrateur",

    // auth
    account: "/mon-compte",
    login: "/connexion",
    notif: "/notification",
    info_parameter: "/parametres-du-compte",
    password_parameter: "/securite-et-confidentialite",

    // setting
    custom: "/customisation",
    setting: "/parametre",

    school_years: "/annees-scolaires",

    subscriptions_sessions: "/sessions-d-inscriptions",

    departments: "/departements",

    cycles: "/cycles",

    filieres: "/filieres",
    filiere: "/filiere",
}