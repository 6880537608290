import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../assets/illustration';
import { deleteDepartment, getDepartments, reset, setDepartment, updateDepartment } from '../../../../../app/redux/features/cursus/department.slice';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { getFilieres, setFiliere, reset as freset } from '../../../../../app/redux/features/cursus/filiere.slice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { getCycles, reset as dreset } from '../../../../../app/redux/features/cursus/cycle.slice';
import { MainLayout } from '../../../../layout';

const AddModal = ({fnc}:{fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
    intitule: "",
    nom_court: "",
    description: "",
  })
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess,department, isCreateError, message } = useAppSelector((state) => state.department);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(setDepartment({
      ...data, 
    }))
  }

  const closeModal = () => {
    setData(null)
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-department'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'un departement</h2>
      </div>
      {
        closeM && isCreateSuccess && department ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Creation réussie avec succès</h1>
                <p>
                  Le departement <strong>{department?.intitule }</strong> a été créée
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isCreateError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science de données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "textarea",
                field_require: false,
                value: data.description,
                label: "Description",
                id: "description",
                onChange: (val: any) => setData({...data, description: val}),
                error: error,
                placeholder: "Décrire un peu le departement",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isCreateLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show: closeM && isCreateSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}
  
const EditModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
  const [data, setData] = useState<any>({
      intitule: item.intitule || "",
      nom_court: item.nom_court || "",
      description: item.description || "",
      statut: item.statut || "",
  })
  const dispatch = useAppDispatch();
  const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.cycle);

  const [closeM, setCloseM] = useState<boolean>(false)
  const [error, setError] = useState<any>(null)

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
  
    setError(null)
    setCloseM(true)
    dispatch(updateDepartment({
      data, 
      id: item.id
    }))
  }

  const closeModal = () => {
    setData({
      intitule:  "",
      finalite:  "",
      description: "",
      statut: "",
    })
    fnc(false)
    setCloseM(false)
  }

  return (
    <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-department'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
          <h2>Modification d'un departement</h2>
          <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
              data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
          }}>
              {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
          </button>
      </div>
      {
        closeM && isEditSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Modification réussie avec succès</h1>
                <p> Les modifications ont été enregistrées.</p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            {
              closeM && isEditError ? (
                <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
              ) : null
            }
            <ModalForm tabsField={[
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.intitule,
                label: "Intitulé",
                id: "intitule",
                onChange: (val: any) => setData({...data, intitule: val}),
                error: error,
                placeholder: "Ex: Science des Données",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "text",
                field_require: true,
                value: data.nom_court,
                label: "Nom court",
                id: "nom_court",
                onChange: (val: any) => setData({...data, nom_court: val}),
                error: error,
                placeholder: "Ex: SDD",
                isShow: true
              },
              {
                field: "textfield",
                field_type: "textarea",
                field_require: false,
                value: data.description,
                label: "Description",
                id: "description",
                onChange: (val: any) => setData({...data, description: val}),
                error: error,
                placeholder: "Décrire un peu le cycle",
                isShow: true
              },
            ]}/>
             
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isEditLoading ? "pi-spinner5" : "pi-add-circle-line", 
            type: "submit", 
            label: isEditLoading ? "Creation en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isEditLoading,
            show: closeM && isEditSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isEditLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
  const dispatch = useAppDispatch();
  const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.department);
  
  const [closeM, setCloseM] = useState<boolean>(false)
  const closeModal = () => {
    fnc(false)
    setCloseM(false)
  }
  return (
    <Modal size={'is--sm'} withClose={true} id={'delete-department'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Suppression d'un departement</h2>
      </div>
      {
        closeM && isDeleteSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Suppression réussie avec succès</h1>
                <p>
                  { "l'element a bien été supprimé" }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
              <div className='kui-modal-box-statut__illustration'>
                {
                  closeM && isDeleteError ? (
                    <FailIllu loop={true} autoplay={true} size={{
                      height: 120,
                      width: 120
                    }}/>
                  ) : (
                    <i className='pi-delete-2-line'></i>
                  )
                }
              </div>
              <div className='kui-modal-box-statut__container'>
                {
                  closeM && isDeleteError ? (
                    <>
                      <h1>Une erreur est survenue</h1>
                      <p>{message}</p>
                    </>
                  ) : (
                    <>
                      <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                      <p>L'action est irreversible</p>
                    </>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      
      <ModalActions tabsActions={[
        {
          icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
          type: "button", 
          label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
          style:"is--primary", 
          active: !isDeleteLoading,
          onClick: () => {
              setCloseM(true)
              dispatch(deleteDepartment(item.id))
          },
          show: closeM && isDeleteSuccess ? false : true
        },
        {
          icon: "pi-close-line", 
          type: "button", 
          label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
          style:"is--neutral", 
          active: !isDeleteLoading,
          onClick: () => closeModal(),
          show: true
        },
      ]}/>
    </Modal>
  )
}

const AddFiliereModal = ({cycles, item, fnc}:{cycles: any[], item: any, fnc: (val: boolean) => void | any}) => {
    
  const [data, setData] = useState<any>({
    intitule:  "",
    nom_court:  "",
    description: "",
    departement: item.id,
  })
  const dispatch = useAppDispatch();
  const { isCreateLoading, isCreateSuccess, isCreateError, message } = useAppSelector((state) => state.filiere);
  const [error, setError] = useState<any>({
      field: "",
      message: ""
  })


  const [cycle, setCycle] = useState<string>("")
  const [cycleI, setCycleI] = useState<any>(null)
  const [closeM, setCloseM] = useState<boolean>(false)
  const [search, setSearch ] = useState<string>("")
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!data.intitule){
      setError({
          field: "intitule",
          message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.nom_court){
        setError({
            field: "nom_court",
            message: "Veuillez renseigner ce champs"
        })
        return
    }
    setCloseM(true)
    dispatch(setFiliere({...data, cycle: cycle}))
  }

  const closeModal = () => {
    fnc(false)
    setCloseM(false)
    setSearch("")
    setData({
      intitule:  "",
      nom_court:  "",
      cycle: "",
    })
    setCycle("")
    setCycleI(null)
  }

  return (
    <Modal size={'is--md'} withClose={!isCreateLoading} id={'edit-reco'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
      <div className='kui-modal-box__header'>
        <h2>Ajout d'une filière au département {item?.intitule}</h2>
      </div>
      {
        closeM && isCreateSuccess ? (
          <div className='kui-modal-box__content'>
            <div className='kui-modal-box__status kui-modal-box-statut is--success'>
              <div className='kui-modal-box-statut__illustration'>
                <AddSuccessIllu loop={true} autoplay={true} size={{
                  height: 120,
                  width: 120
                }}/>
              </div>
              <div className='kui-modal-box-statut__container'>
                <h1>Ajout d'une filière au departement {item?.intitule}</h1>
                <p>
                  Les informations ont été modifiées
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='kui-modal-box__content'>
              {
                  closeM && isCreateError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                  ) : null
              }
                      
              <div className='kui-modal-box-group'>
                  {
                      cycle ? (
                          <>
                            <div className={`kui-modal-box-list-item`}>
                                <div className='kui-modal-box-list-item__avatar is--rounded'>
                                    <i className="pi-school-line"></i>
                                </div>
                                <div className='kui-modal-box-list-item__container'>
                                    <strong>{cycleI?.intitule}</strong>
                                    <span>{cycleI.finalite}</span>
                                    <button type='button' className='kui-link' onClick={() => {
                                        setCycle("")
                                        setCycleI(null)
                                    }}>
                                        <span>Changer</span>
                                    </button>
                                </div>
                            </div>
                            
                            <ModalForm tabsField={[
                              {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.intitule,
                                label: "Intitulé",
                                id: "intitule",
                                onChange: (val: any) => setData({...data, intitule: val}),
                                error: error,
                                placeholder: "Ex: Science de la santé",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "text",
                                field_require: true,
                                value: data.nom_court,
                                label: "Nom court",
                                id: "nom_court",
                                onChange: (val: any) => setData({...data, nom_court: val}),
                                error: error,
                                placeholder: "Ex: SDS",
                                isShow: true
                              },
                              {
                                field: "textfield",
                                field_type: "textarea",
                                field_require: false,
                                value: data.description,
                                label: "Description",
                                id: "description",
                                onChange: (val: any) => setData({...data, description: val}),
                                error: error,
                                placeholder: "Décrire un peu la filière",
                                isShow: true
                              },
                            ]}/>
                          </>
                      ) : (
                        <>
                          <div className='kui-modal-box-group__header'><h3>Choix du cycle</h3></div>
                          <div className='kui-modal-box-list'>
                              <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                  <div className='kui-modal-box-list-action-search'>
                                    <i className="pi-search-line"></i>
                                    <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                  </div>
                              </div>
                            {
                              search.trim().length > 2 ? (
                                cycles
                                .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                .length > 0 ? (
                                    <div className='kui-modal-box-list__container'>
                                    {
                                        cycles
                                        .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                        .slice(0, 8)
                                        .map((itemo: any, index: number) => (
                                        <button key={index} type='button' className={`kui-modal-box-list-item ${cycle === itemo?.id ? "active" : ""}`} onClick={() => {
                                            setCycle(itemo?.id)
                                            setCycleI(itemo)
                                        }}>
                                            {
                                                cycle === itemo?.id ? (
                                                  <div className='kui-modal-box-list-item__check'>
                                                    <i className="pi-minimize-line"></i>
                                                  </div>
                                                ) : null
                                            }
                                            <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                <i className="pi-school-line"></i>
                                            </div>
                                            <div className='kui-modal-box-list-item__container'>
                                                <span>Code: {itemo?.slug}</span>
                                                <strong>{itemo?.intitule}</strong>
                                                <span>{itemo?.finalite}</span>
                                            </div>
                                        </button>
                                        ))
                                    }
                                    
                                    </div>
                                ) : (
                                  <span>Aucun cycle trouvé</span>
                                )
                              ) : (
                                <span>Rechercher...</span>
                              )
                            }
                          </div>
                        </>
                      )
                  }
              </div>
                  
          </div>
        )
      }
      <form onSubmit={handleSubmit}>
        <ModalActions tabsActions={[
          {
            icon: isCreateLoading ? "pi-spinner5" : "pi-save-2-line", 
            type: "submit", 
            label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
            style:"is--primary", 
            active: !isCreateLoading,
            show:  (closeM && isCreateSuccess ? false : true)
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
            style:"is--danger", 
            active: !isCreateLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </form>
    </Modal>
  )
}

const FiliereContainer = ({cycles, item, fnc}:{cycles: any[], item: any, fnc: (val: boolean) => void | any}) => {
  const [search, setSearch] = useState<string>("")
  const [addFiliereModal, setAddFiliereModal] = useState<boolean>(false)
  const { isLoading, isSuccess, isError, message, filieres } = useAppSelector((state) => state.filiere);
  
  return (
      <div className='kui-modal-full-page__list kui-modal-full-page-list'>
          <h2>Filières</h2>
          <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
              <div className='kui-page-list-actions-search'>
                <i className="pi-search-line"></i>
                <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e?.target?.value)}/>
              </div>
              <div className='kui-page-list-actions-group'>
                <Button label={'Ajouter une filière'} iconLeftClass='pi-add-circle-line'  btype='button' bstyle='is--primary' onClick={() => {
                    setAddFiliereModal(true)
                    fnc(false)
                }}/>
              </div>
          </Grid>
          <br />

          {
              isLoading ? (
                <div className='kui-loader'>
                  <LoaderAnimation loop={true} autoplay={true} size={{
                      height: 40,
                      width: 40
                  }}/>
                </div>
              ) : (
                  isError ? (
                      <div className='kui-page-error'>
                        <div className='kui-page-error__illustration'>
                          <NotFoundError loop={true} autoplay={true} size={{
                            height: 180,
                            width: 180
                          }}/>
                        </div>
                        <div className='kui-page-error_details'>
                          <p>Oupps! Une erreur est survenue</p>
                          <h1>{message}</h1>
                        </div>
                      </div>
                  ) : (
                      isSuccess && filieres ? (
                          filieres
                          .filter((el: any) => el.departement  === item?.id)
                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                          .length > 0 ? (
                              <div className='kui-modal-full-page-list__container'>
                                  <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.5}>
                                  {
                                      filieres?.filter((el: any) => el.cycle  === item?.id)
                                      .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                      .map((itemo: any, index: number) => (
                                      <div className='kui-modal-full-page-list-item' key={index}>
                                          <div className='kui-modal-full-page-list-item__container'>
                                          <div className='kui-modal-full-page-list-item__illustration'>
                                              <i className="pi-documents-line"></i>
                                          </div>
                                          <div className='kui-modal-full-page-list-item__text'>
                                              <strong>{itemo?.intitule}</strong>
                                              <span>{`Nom court: ${itemo?.nom_court}`}</span>
                                          </div>
                                          </div>
                                      </div>
                                      ))
                                  }
                                  </Grid>
                              </div>
                          ) : (
                              <div className='kui-modal-empty'>
                                  <EmptyList loop={true} autoplay={true} size={{
                                      height: 190,
                                      width: 290
                                  }}/>
                                  <span>Aucun element trouvé</span>
                              </div>
                          )
                      ) : null
                  )
              )
          }
          {
              addFiliereModal && cycles && item && <AddFiliereModal cycles={cycles} item={item} fnc={(val: boolean) => setAddFiliereModal(val) }/>
          }
      </div>
  )
}


export const DepartmentPage = () => {
  const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.departments}`)
      }
    }, [token, navigate]);

    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, departments, message } = useAppSelector((state) => state.department);
    const { isLoading: isCLoading, isSuccess: isCSuccess, isError: isCError, message: cMessage, cycles } = useAppSelector((state) => state.cycle);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const display = searchParams.get('display')
    const n = searchParams.get('number')
    const q = searchParams.get('q')
    const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
    const [search, setSearch] = useState<string>(q  || "")
    const [selectItemModal, setSelectItemModal] = useState<any>(null)
    
    const [showItemModal, setShowItemModal] = useState<boolean>(false)
    const [addModal, setAddModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
  
    const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        navigate(links.departments+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
    }
    
    const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
      setItemNumber(parseInt(e.target.value))
      if(parseInt(e.target.value) > 0){
          navigate(links.departments+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
      }
    }
    useEffect(() => {
      dispatch(getCycles({}))
      dispatch(getDepartments({}))
      dispatch(getFilieres({}))
      return () => {
        dispatch(reset())
        dispatch(dreset())
        dispatch(freset())
      }
    }, [dispatch])

    return (
      <MainLayout title="Departement" isMin={true}>
        <div className='kui-page is--min'>
          <div className='kui-arianne'>
            <Link className='kui-link' to={links.home}>
                <i className="pi-home-6-line"></i>
                <span>Accueil</span>
            </Link>
            <span> | </span>
            <p>Departement</p>
          </div>

          {
            isLoading || isCLoading ? (
              <div className='kui-loader'>
                  <LoaderAnimation loop={true} autoplay={true} size={{
                      height: 40,
                      width: 40
                  }}/>
              </div>
            ) : (
              <>
                  <div className='kui-page__header kui-page-header'>
                      <h2>Departements</h2>
                  </div>
                  <div className='kui-page-list'>
                      <div className='kui-page-list__actions kui-page-list-actions'>
                          <div className='kui-page-list-actions-display'>
                              <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.departments+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                <i className="pi-list-check-line"></i>
                              </button>
                              <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.departments+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                <i className="pi-layout-grid-line"></i>
                              </button>
                          </div>
                          <div className='kui-page-list-actions-group'>
                              <div className='kui-page-list-actions-input'>
                                <span>Nombre à afficher: </span>
                                <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                              </div>
                              <div className='kui-page-list-actions-search'>
                                <i className="pi-search-line"></i>
                                <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                              </div>
                          </div>
                          <div className='kui-page-list-actions-group'>
                            <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                          </div>
                      </div>
                      {
                          isCError || isError ? (
                              <div className='kui-page-error'>
                                <div className='kui-page-error__illustration'>
                                  <NotFoundError loop={true} autoplay={true} size={{
                                    height: 180,
                                    width: 180
                                  }}/>
                                </div>
                                <div className='kui-page-error_details'>
                                  <p>Oupps! Une erreur est survenue</p>
                                  <h1>{isError ? message : isCError ? cMessage : ""}</h1>
                                </div>
                              </div> 
                          ) : (
                              isSuccess && isCSuccess && departments && cycles ? (
                                  <div className='kui-page-list__container'>
                                      <span className='kui-page-list__total'>
                                        {
                                            departments.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                            .length > 0 ? `
                                            ${departments.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                .length > 1 ? departments.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                .length + " elements trouvés" : departments.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                .length+" element trouvé"} ` : "Aucun element trouvé"
                                        }
                                      </span>

                                      {
                                          departments
                                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                          .length > 0 ? (
                                              display === "grid" ? (
                                              <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                  {
                                                      departments
                                                      .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                      .slice(0, itemNumber)
                                                      .map((item: any, index: number) => (
                                                          <div className='kui-page-list-item is--md' key={index}>
                                                              <div className='kui-page-list-item__min'>
                                                                  <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                    {item.statut}
                                                                  </span>
                                                              </div>
                                                              

                                                              <div className='kui-page-list-item__container'>
                                                                  <div className='kui-page-list-item__details'>
                                                                      <strong className='kui-page-list-item__title'>{item?.intitule}</strong>
                                                                      <p>{`Nom court: ${item.nom_court}`}</p>
                                                                  </div>
                                                              </div>
                                                              <div className='kui-page-list-item__actions'>
                                                                  <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                      setSelectItemModal(null)
                                                                      setSelectItemModal(item)
                                                                      setShowItemModal(true)
                                                                  }}>
                                                                      <i className={"pi-eye-line"}></i>
                                                                      <span>{"Apercu"}</span>
                                                                  </button>
                                                                  <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                      setSelectItemModal(null)
                                                                      setSelectItemModal(item)
                                                                      setEditModal(true)
                                                                  }}>
                                                                      <i className={"pi-edit-3-line"}></i>
                                                                      <span>{"Modifier"}</span>
                                                                  </button>
                                                                  <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                      setSelectItemModal(null)
                                                                      setSelectItemModal(item)
                                                                      setDeleteModal(true)
                                                                  }}>
                                                                      <i className={"pi-delete-2-line"}></i>
                                                                      <span>{"Supprimer"}</span>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      ))        
                                                  }
                                              </Grid>
                                              ) : (
                                              <Table>
                                                  <Row rtype='header'>
                                                      <Column ctype={'small'}>
                                                          <strong>#</strong>
                                                      </Column>
                                                      <Column ctype={'large'}>
                                                          <strong>Intitulé</strong>
                                                      </Column>
                                                      <Column ctype={'mid'}>
                                                          <strong>Statut</strong>
                                                      </Column>
                                                      <Column ctype={'small'}>
                                                          <strong>#</strong>
                                                      </Column>
                                                  </Row>
                                                  {
                                                      departments
                                                      .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                      .slice(0, itemNumber)
                                                      .map((item: any, index: number) => (
                                                          <Row key={index}>
                                                              <Column ctype={'small'}>
                                                                  <strong>{`#${index + 1}`}</strong>
                                                              </Column>
                                                              <Column ctype={'large'}>
                                                                  <TableItem title={item?.intitule} subtitle={item.nom_court} withImage={false} image={null}/>
                                                              </Column>
                                                              <Column ctype={'mid'}>
                                                                  <div>
                                                                      <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                      {item.statut}
                                                                      </span>
                                                                  </div>
                                                              </Column>
                                                              <Column ctype={'small'}>
                                                                  <TableOptions atype={"reduce"} tabs={[
                                                                  {
                                                                    icon: "pi-eye-line", 
                                                                    label: "Aperçu", 
                                                                    style:"is--neutral", 
                                                                    onClick: () => {
                                                                      setSelectItemModal(null)
                                                                      setShowItemModal(true)
                                                                      setSelectItemModal(item)
                                                                    }
                                                                  },
                                                                  {
                                                                    icon: "pi-edit-3-line", 
                                                                    label: "Modifier", 
                                                                    style:"is--neutral", 
                                                                    onClick: () => {
                                                                      setSelectItemModal(null)
                                                                      setSelectItemModal(item)
                                                                      setEditModal(true)
                                                                    }
                                                                  },
                                                                  {
                                                                    icon: "pi-delete-2-line", 
                                                                    label: "Supprimer", 
                                                                    style:"is--danger", 
                                                                    onClick: () => {
                                                                      setSelectItemModal(null)
                                                                      setSelectItemModal(item)
                                                                      setDeleteModal(true)
                                                                    }
                                                                  },
                                                                  ]}/>
                                                              </Column>
                                                          </Row>
                                                      ))
                                                  }
                                              </Table>
                                              )
                                          ) : (
                                              <div className='kui-page-empty'>
                                                  <div className='kui-page-empty__illustration'>
                                                      <EmptyList loop={true} autoplay={true} size={{
                                                      height: (380 / 1.3),
                                                      width: (420 / 1.3)
                                                      }}/>
                                                  </div>
                                                  <div className='kui-page-empty__details'>
                                                      <h1>Aucun element trouvé</h1>
                                                  </div>
                                              </div>
                                          )
                                      }
                                  </div>
                              ) : null
                          )
                      }
                  </div>
                  {addModal ? <AddModal fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
                  {editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                  
                  {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}

                  {showItemModal && selectItemModal ? (
                    <Modal size={'is--full-page'} withClose={true} id={'show-category'} isOpen={true} fnc={(val: boolean) => setShowItemModal(val)}>
                        <div className='kui-modal-box__header'>
                            <h2>Aperçue d'un departement</h2>
                        </div>

                        <div className='kui-modal-full-page'>
                            <div className='kui-modal-full-page__side'>
                                <div className='kui-modal-full-page__illustration kui-modal-full-page-illustration is--left'>
                                    <div className='kui-modal-full-page-illustration__container is--square'>
                                        <span>{selectItemModal.nom_court}</span>
                                    </div>
                                </div>
                                <div className='kui-modal-full-page-details'>
                                    <h1>{selectItemModal?.intitule}</h1>
                                    <p>Nom court: {selectItemModal.nom_court}</p>
                                    <div className='kui-modal-full-page-details-description'>
                                        <strong>Description</strong>
                                        <p>{selectItemModal?.description}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='kui-modal-full-page__container'>
                              <div className='kui-modal-full-page__section'>
                                <FiliereContainer item={selectItemModal} cycles={cycles || []} fnc={(val?: boolean) => setEditModal(val || false)}/>
                              </div>
                            </div>
                        </div>
                        
                        <ModalActions tabsActions={[
                          {
                            icon: "pi-edit-3-line", 
                            type: "button", 
                            label:"Modifier", 
                            style:"is--primary", 
                            active: true,
                            onClick: () => {
                                setShowItemModal(false)
                                setEditModal(true)
                            },
                            show: true
                          },
                          {
                            icon: "pi-delete-2-line", 
                            type: "button", 
                            label:"Supprimer", 
                            style:"is--danger", 
                            active: true,
                            onClick: () => {
                                setShowItemModal(false)
                                setDeleteModal(true)
                            },
                            show: true
                          },
                          {
                            icon: "pi-close-line", 
                            type: "button", 
                            label:"Fermer", 
                            style:"is--neutral", 
                            active: true,
                            onClick: () => {
                                setShowItemModal(false)
                            },
                            show: true
                          },
                        ]}/>
                    </Modal>
                  ) : null}  
              </>
            )
          }
        </div>
      </MainLayout>
    )
}
