import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { UserState } from '../../interface/GeneralStateInterface';

const AUTHURI = API_URL+"authentications"

const initialState: UserState = {
    users: [],
    roles: [],
    user: null,
    profile: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isRoleError: false,
    isRoleLoading: false,
    isRoleSuccess: false,

    isDeleteError: false,
    isDeleteLoading: false,
    isDeleteSuccess: false,

    isCreateError: false,
    isCreateLoading: false,
    isCreateSuccess: false,
    
    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,

    isRecoverError: false,
    isRecoverLoading: false,
    isRecoverSuccess: false,

    isProfileError: false,
    isProfileLoading: false,
    isProfileSuccess: false,

    message: null,

}

export const getUsers = createAsyncThunk<any, Object>(
    'user/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getRoles = createAsyncThunk<any, Object>(
    'user/list-roles',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/roles/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const getUser = createAsyncThunk<any, Object>(
    'user/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/${content}/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const setUser = createAsyncThunk<any, Object>(
    'user/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const setProfiles = createAsyncThunk<any, Object>(
    'user/create-profile',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/profile/`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)
export const deleteUser = createAsyncThunk<any, Object>(
    'user/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/${content}/`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const recoverPasswordUser = createAsyncThunk<any, Object>(
    'user/recover-password',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/change_password_by_admin/`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const changeRoleUser = createAsyncThunk<any, Object>(
    'user/change-role-user',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/${content.id}/`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.isCLoading = false
            state.isCSuccess = false
            state.isCError = false
            state.isCPLoading = false
            state.isCPSuccess = false
            state.isCPError = false
            state.isDeleteLoading = false
            state.isDeleteSuccess = false
            state.isDeleteError = false
            state.isRecoverLoading = false
            state.isRecoverSuccess = false
            state.isRecoverError = false
            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.users = action.payload
            })
            .addCase(getUsers.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.users = []
            })

            .addCase(getRoles.pending, (state) => {
                state.isRoleLoading = true
            })
            .addCase(getRoles.fulfilled, (state, action) => {
                state.isRoleLoading = false
                state.isRoleSuccess = true
                state.roles = action.payload
            })
            .addCase(getRoles.rejected, (state, action) => {
                state.isRoleLoading = false
                state.isRoleError = true
                state.message = action.payload
                state.roles = []
            })

            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.user = action.payload
            })
            .addCase(getUser.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
                state.user = null
            })

            .addCase(setUser.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setUser.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.users = [action.payload, ...state.users]
                state.user = action.payload
            })
            .addCase(setUser.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(setProfiles.pending, (state) => {
                state.isProfileLoading = true
            })
            .addCase(setProfiles.fulfilled, (state, action) => {
                state.isProfileLoading = false
                state.isProfileSuccess = true
                state.profile = action.payload
            })
            .addCase(setProfiles.rejected, (state, action) => {
                state.isProfileLoading = false
                state.isProfileError = true
                state.message = action.payload
            })

            .addCase(deleteUser.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.users = state.users.filter((admin: any) => admin.slug !== action.payload?.id)
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

            .addCase(recoverPasswordUser.pending, (state) => {
                state.isRecoverLoading = true
            })
            .addCase(recoverPasswordUser.fulfilled, (state, action) => {
                state.isRecoverLoading = false
                state.isRecoverSuccess = true
            })
            .addCase(recoverPasswordUser.rejected, (state, action) => {
                state.isRecoverLoading = false
                state.isRecoverError = true
                state.message = action.payload
            })
    }
})

export const { reset } = userSlice.actions
export default userSlice.reducer