import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { login } from '../../../../../app/redux/features/auth/auth.slice';
import { links } from '../../../../routes';
import { UserAuthContext } from '../../../../../context/AuthContext';
import { useAppDispatch, useAppSelector } from '../../../../../app/redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../../../layout';
import { Button, Grid, KAlert, Textfield } from '../../../../components';

export const LoginPage = () => {
    const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const url = searchParams.get('url')

  const [data, setData] = useState<any>({
    email: "", 
    password: ""
  }) 

  const [error, setError] = useState<any>(null) 
  let navigate = useNavigate()

  const dispatch = useAppDispatch();
  const { isLoginLoading, isLoginError, isLoginSuccess, message } = useAppSelector((state) => state.auth);
  const { token } = useContext(UserAuthContext); 

  useEffect(() => {
    if(token){
      navigate(url || links.home)
    }
  }, [token, navigate, url]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if(!data.email){
      setError({
        field: "email",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    if(!data.password){
      setError({
        field: "password",
        message: "Veuillez renseigner ce champs"
      })
      return
    }
    console.log(data)
    setError(null)
    dispatch(login(data))
  }

  useEffect(() => {
    if(isLoginSuccess){
      window.location.reload()
      // console.log(isLoginSuccess)
    }
  }, [navigate, isLoginSuccess]);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit} className={"kui-auth-form"}>
        <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={1.2}>
          {
            isLoginError ? (
              <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
            ) : null
          }
          <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
              <div className='kui-auth-form__field kui-auth-form-field'>
                <Textfield 
                  id={'email'} 
                  value={data?.email} 
                  require={true} 
                  changeValue={(value: any) => setData({...data, email: value})} 
                  type={'text'}
                  placeholder="Entrer votre email"
                  label={"Email"}
                  error={error}
                />
              </div>
              <div className='kui-auth-form__field kui-auth-form-field'>
                <Textfield 
                  id={'password'} 
                  value={data?.password} 
                  require={true} 
                  changeValue={(value: any) => setData({...data, password: value})} 
                  type={'password'}
                  placeholder="Entrer votre mot de passe"
                  label={"Mot de passe"}
                  error={error}
                />
              </div>
          </Grid>
          <Grid direction={'fd-column'} spacing={'jc-start'} align={'start'} wrap={false} gap={.8}>
              <div className='kui-auth-form__action kui-auth-form-action'>
                <Button label={isLoginLoading ? "Connexion en cours..." : "Connexion"} btype='submit' bstyle='is--primary' bsize='is--large' active={!isLoginLoading} iconLeftClass={isLoginLoading ? `pi-spinner5` : ""}/>
              </div>
          </Grid>
          <div className='kui-auth-form__info kui-auth-form-info'>
            <p>En cas de perte de mot de passe, veuillez contacter un administrateur.</p>
          </div>
        </Grid>
      </form>
    </AuthLayout>
  )
}
