import React, {createContext, useEffect, useState} from 'react';
import { useAppDispatch, useAppSelector } from '../app/redux/store';
import { getMe, logout } from '../app/redux/features/auth/auth.slice';


export const UserAuthContext = createContext<null | any>(null);

interface AuthContextParams {
    children?: React.ReactNode
}


export const AuthContext: React.FC<AuthContextParams> = ({children}) => {
    const [info, setInfo] = useState<any | null>(null)
    const [structureChoice, setStructureChoise] = useState<string>("")

    const dispatch = useAppDispatch();
    const {token, meAuth, isLoading, isError, message} = useAppSelector((state) => state.auth);

    useEffect(() => {
        if(token){
            dispatch(getMe({}))
        }

    }, [dispatch, token])

    useEffect(() => {

        setInfo(meAuth)

    }, [meAuth])


    const logOut = () => {
        
        dispatch(logout({}))
        window.location.reload()
    }

    useEffect(() => {
        if(isError){
            
            dispatch(logout({}))
        }
    }, [dispatch, isError]);

    return (
        <UserAuthContext.Provider value={{
            info,
            token,
            setInfo,
            logOut,
            isLoading,
            isError,
            message,
            structureChoice, 
            setStructureChoise
        }}>
            {children}
        </UserAuthContext.Provider>
    );
};
