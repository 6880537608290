import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { AuthState } from '../../interface/GeneralStateInterface';
import axios from "axios"
import { getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { API_URL } from '../../../utils';

const token = window.localStorage.getItem('cfa-admin-auth')
const user_slug = window.localStorage.getItem('cfa-admin-auth-user-slug')

const AUTHURI = API_URL+"authentications"

const initialState: AuthState = {
    token: token ? token : "",
    meAuth: null,
    meProfile: null,
    isLoginError: false,
    isLoginLoading: false,
    isLoginSuccess: false,
    isError: false,
    isLoading: false,
    isSuccess: false,
    isProfileError: false,
    isProfileLoading: false,
    isProfileSuccess: false,
    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,
    isPasswordLoading: false,
    isPasswordError: false,
    isPasswordSuccess: false,
    isAvatarError: false,
    isAvatarLoading: false,
    isAvatarSuccess: false,
    message: null
}


export const login = createAsyncThunk<any, Object>(
    'auth/login',
    async (data: any, thunkAPI) => {
        try {
            console.log(AUTHURI+"/token/token_obtain/")
            const response = await axios.post( AUTHURI+"/token/token_obtain/", data)
            
            if(response){
                console.log(response)
                let tokens = response.data.access
                let user_slug = response.data.slug
                let user_id = response.data.id
                window.localStorage.setItem('cfa-admin-auth', tokens)
                window.localStorage.setItem('cfa-admin-auth-user-slug', user_slug)
                window.localStorage.setItem('cfa-admin-auth-user-id', user_id)
                return response.data   
            }
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const getMe = createAsyncThunk<any, Object>(
    'auth/me',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/users/${user_slug}/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const getProfileInfo = createAsyncThunk<any, Object>(
    'auth/profile',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/profiles/${content}/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updateAvatar = createAsyncThunk<any, Object>(
    'auth/update-avatar',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AUTHURI}/profiles/${content.id}/`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updateInformation = createAsyncThunk<any, Object>(
    'auth/update-information',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AUTHURI}/profiles/${content.id}/`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const updateUserInformation = createAsyncThunk<any, Object>(
    'auth/update-user-information',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AUTHURI}/users/${content.id}/`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)


export const changePassword = createAsyncThunk<any, Object>(
    'auth/change-password',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${AUTHURI}/change_password/`,
                token: tokens,
                content: content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const logout = createAsyncThunk<any, Object>(
    'auth/logout',
    async () => {
        window.localStorage.removeItem('cfa-admin-auth')
        window.localStorage.removeItem('cfa-admin-auth-user-slug')
        window.localStorage.removeItem('cfa-admin-auth-user-id')
    }
)

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state: any) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false

            state.isEditError = false
            state.isEditLoading = false
            state.isEditSuccess = false

            state.isLoginError = false
            state.isLoginLoading = false
            state.isLoginSuccess = false

            state.isProfileError = false
            state.isProfileLoading = false
            state.isProfileSuccess = false

            state.isPasswordError = false
            state.isPasswordLoading = false
            state.isPasswordSuccess = false

            state.isAvatarError = false
            state.isAvatarLoading = false
            state.isAvatarSuccess = false

            state.message = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoginLoading = true
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoginLoading = false
                state.isLoginSuccess = true
                state.meAuth = action.payload
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoginLoading = false
                state.isLoginError = true
                state.message = action.payload
                state.meAuth = null
            })
            .addCase(getMe.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getMe.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.meAuth = action.payload
            })
            .addCase(getMe.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload

            })
            .addCase(getProfileInfo.pending, (state) => {
                state.isProfileLoading = true
            })
            .addCase(getProfileInfo.fulfilled, (state, action) => {
                state.isProfileLoading = false
                state.isProfileSuccess = true
                state.meProfile = action.payload
            })
            .addCase(getProfileInfo.rejected, (state, action) => {
                state.isProfileLoading = false
                state.isProfileError = true
                state.message = action.payload

            })
            .addCase(updateUserInformation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateUserInformation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.meAuth = action.payload
            })
            .addCase(updateUserInformation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload

            })
            .addCase(updateInformation.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateInformation.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.meProfile = action.payload
            })
            .addCase(updateInformation.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload

            })
            .addCase(updateAvatar.pending, (state) => {
                state.isAvatarLoading = true
            })
            .addCase(updateAvatar.fulfilled, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarSuccess = true
                // state.meProfile = action.payload
            })
            .addCase(updateAvatar.rejected, (state, action) => {
                state.isAvatarLoading = false
                state.isAvatarError = true
                state.message = action.payload

            })
            .addCase(changePassword.pending, (state) => {
                state.isPasswordLoading = true
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordSuccess = true
                state.meProfile = action.payload
            })
            .addCase(changePassword.rejected, (state, action) => {
                state.isPasswordLoading = false
                state.isPasswordError = true
                state.message = action.payload

            })
            .addCase(logout.fulfilled, (state) => {
                state.token = ""
            })
    },
})

export const { reset } = authSlice.actions
export default authSlice.reducer