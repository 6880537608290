import React, { useContext } from 'react'
import { MainLayout } from '../../../../../layout'
import { Link } from 'react-router-dom'
import { links } from '../../../../../routes'
import { ThemeContext } from '../../../../../../context/ThemeContext'
import { Button, Grid } from '../../../../../components'


export const CustomPage = () => {

  const {
    theme,
    defaultDark,
    defaultTheme,
    colorPalette,
    switchPaletteColor,
    switchTheme,
    swichtDefautTheme
  } = useContext(ThemeContext);

  return (
   
    <MainLayout title="Application" here='setting' isMin={true}>
      
      <div className='kui-page is--min'>
      <div className='kui-arianne'>
        <Link className='kui-link' to={links.home}>
          <i className="pi-home-6-line-2"></i>
          <span>Accueil</span>
        </Link>
        <span> | </span>
        <p>Application</p>
      </div>
        <div className='kui-page__container'>
          <div className='kui-page__header kui-page-header'>
            <h1>⚙️</h1>
            <h2>Application</h2>
          </div>
          <div className='kui-page__custom kui-page-custom'>
            <div className='kui-page-custom__option'>
              <strong>Schema de couleurs</strong>
              <div className='kui-page-custom__colors kui-page-custom-colors'>
                <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "default" ? "is--active" : ""}`} onClick={() => switchPaletteColor("default")}>
                    <div className='kui-page-custom-color__container default'></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-2" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-2")}>
                    <div className='kui-page-custom-color__container palette-2'></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-3" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-3")}>
                    <div className={`kui-page-custom-color__container ${defaultTheme === "default" ? defaultDark ? "palette-3-d" : "palette-3-l" : theme === "dark" ? "palette-3-d" : "palette-3-l"} `}></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-4" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-4")}>
                    <div className='kui-page-custom-color__container palette-4'></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-5" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-5")}>
                    <div className='kui-page-custom-color__container palette-5'></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-6" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-6")}>
                    <div className='kui-page-custom-color__container palette-6'></div>
                  </button>
                  <button type='button' className={`kui-page-custom-color ${colorPalette === "palette-7" ? "is--active" : ""}`} onClick={() => switchPaletteColor("palette-7")}>
                    <div className='kui-page-custom-color__container palette-7'></div>
                  </button>
                </Grid>
              </div>
            </div>

            <div className='kui-page-custom__option'>
              <strong>Thème:  {defaultTheme === "default" ? "Par defaut" : (theme === "dark" ? "Sombre" : "Clair")}</strong>
              <div className={"kui-page-custom__theme kui-page-custom-theme"}>
                <button type={"button"} className={`kui-page-custom-theme-switch ${defaultTheme === "default" ? (defaultDark ? "is--active" : "") : (theme === "dark" ? "is--active" : "")}`} onClick={switchTheme}>
                  <div className={`kui-page-custom-theme-switch__content ${defaultTheme === "default" ? (defaultDark ? "is--active" : "") : (theme === "dark" ? "is--active" : "")}`}></div>
                  </button>
                  <Button label={'Retablir le thème par défaut'} btype='button' onClick={swichtDefautTheme}/>
                 
              </div>
            </div>
          </div>
        </div>

      </div>
    </MainLayout>
  )
}
