import React, { FC } from 'react'
import { AuthLayoutParams } from '../type/auth'
import images from '../../../assets/images'
import { titlePage } from '../../../../app/utils'
import { LogoIcon } from '../../../assets/illustration'
import { useNavigate } from 'react-router-dom'
import { links } from '../../../routes'

export const AuthLayout: FC<AuthLayoutParams> = ({withBack = false, title = "Connexion", subtitle= "Accès reservé", image = images.auth, children}) => {
  titlePage(title)
  let navigate = useNavigate()
  return (
    <div className='kui-auth-layout'>
      <div className='kui-auth-layout__container'>
        {
          withBack ? (
            <div className='kui-auth-layout__back'>
              <button type='button' onClick={() => navigate(links.home)}>
                <i className="pi-home-6-line"></i>
                <span>Retour à l'accueil</span>
              </button>
            </div>
          ) : null
        }
        <header className='kui-auth-layout__header'>
          <div className='kui-auth-layout__logo'>
            <LogoIcon/>
          </div>
          <div className='kui-auth-layout__title'>
            <h1>{title}</h1>
            <span>{subtitle}</span>
          </div>
        </header>
        <main role='main' className='kui-auth-layout__main'>
          {children}
        </main>
        <footer className='kui-auth-layout__footer'>
          <p>©2024 CFA Université</p>
        </footer>
      </div>
      <div className='kui-auth-layout__image'>
        <img src={image} alt={title} />
      </div>
    </div>
  )
}
