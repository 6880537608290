import React from 'react'
import { MainLayout } from '../../../../layout'
import { links } from '../../../../routes'
import { Link } from 'react-router-dom'
import { EmptyNotification } from '../../../../assets/illustration'

export const NotifPage = () => {
  return (
    <MainLayout title="Boite de reception" here='notification'>
      <div className='kui-arianne'>
        <Link className='kui-link' to={links.home}>
          <i className="pi-home-6-line"></i>
          <span>Accueil</span>
        </Link>
        <span> | </span>
        <p>Boite de reception</p>
      </div>
      <div className='kui-page is--min'>
        <div className='kui-page-empty is--huge'>
          <div className='kui-page-empty__illustration'>
            <EmptyNotification loop={true} autoplay={true} size={{
              height: (380 / 1.3),
              width: (420 / 1.3)
            }}/>
          </div>
          <div className='kui-page-empty__details'>
            <h1>Aucune notification</h1>
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
