import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import auth from "../features/auth/auth.slice"
import user from "../features/user/user.slice"
import school_year from "../features/school_year/school_year.slice"
import cycle from "../features/cursus/cycle.slice"
import filiere from "../features/cursus/filiere.slice"
import department from "../features/cursus/department.slice"
import unite_enseignement from "../features/cursus/unite_enseignement.slice"
import semestre from "../features/cursus/semestre.slice"
import matiere from "../features/cursus/matiere.slice"
import niveau from "../features/cursus/niveau.slice"
import filiere_niveau from "../features/cursus/filiere_niveau.slice"

export const store = configureStore({
    reducer: {
        auth,
        user,
        school_year,
        department,
        filiere,
        cycle,
        unite_enseignement,
        semestre,
        matiere,
        niveau,
        filiere_niveau
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AddDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AddDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;