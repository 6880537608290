import React, { FormEvent, useContext, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../../app/redux/store';
import { deleteFiliere, getFilieres, reset, setFiliere, updateFiliere } from '../../../../../../app/redux/features/cursus/filiere.slice';
import { Button, Column, Grid, KAlert, Modal, ModalActions, ModalForm, Row, Table, TableItem, TableOptions } from '../../../../../components';
import { AddSuccessIllu, EmptyList, FailIllu, LoaderAnimation, NotFoundError } from '../../../../../assets/illustration';
import { links } from '../../../../../routes';
import { UserAuthContext } from '../../../../../../context/AuthContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getCycles, reset as dreset } from '../../../../../../app/redux/features/cursus/cycle.slice';
import { getDepartments, reset as freset } from '../../../../../../app/redux/features/cursus/department.slice';
import { MainLayout } from '../../../../../layout';


const AddModal = ({cycles, departments, fnc}:{cycles: any[], departments: any[], fnc: (val: boolean) => void | any}) => {
    
    const [data, setData] = useState<any>({
      intitule:  "",
      nom_court:  "",
      description: "",
    })
    const dispatch = useAppDispatch();
    const { isCreateLoading, isCreateSuccess, isCreateError, filiere, message } = useAppSelector((state) => state.filiere);
    const [error, setError] = useState<any>({
        field: "",
        message: ""
    })
  
  
    const [cycle, setCycle] = useState<string>("")
    const [cycleI, setCycleI] = useState<any>(null)
  
    const [department, setDepartment] = useState<string>("")
    const [departmentI, setDepartmentI] = useState<any>(null)

    const [closeM, setCloseM] = useState<boolean>(false)
    const [search, setSearch ] = useState<string>("")
    const [searchD, setSearchD ] = useState<string>("")
    
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if(!data.intitule){
        setError({
            field: "intitule",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.nom_court){
          setError({
              field: "nom_court",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
      if(!cycle){
          setError({
              field: "global",
              message: "Veuillez choisir un cycle"
          })
          return
      }
      if(!department){
          setError({
              field: "global",
              message: "Veuillez choisir un departement"
          })
          return
      }
      setCloseM(true)
      dispatch(setFiliere({...data, cycle: cycle, departement: department}))
    }
  
    const closeModal = () => {
      fnc(false)
      setCloseM(false)
      setSearch("")
      setData({
        intitule:  "",
        nom_court:  "",
        cycle: "",
      })
    }
  
    return (
      <Modal size={'is--md'} withClose={!isCreateLoading} id={'add-filiere'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Ajout d'une filière </h2>
        </div>
        {
          closeM && isCreateSuccess && filiere ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Ajout d'une filière</h1>
                  <p>
                    Le cycle <strong>{filiere?.intitule }</strong> a été créée
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
                {
                    closeM && isCreateError ? (
                        <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                    ) : null
                }
                {
                    error?.field === "global" ? (
                        <KAlert status={"st--danger"} title={"Ouppss!!!"} message={error?.message}/>
                    ) : null
                }
                        
                <div className='kui-modal-box-group'>
                    {
                        cycle ? (
                            <>
                              <div className={`kui-modal-box-list-item`}>
                                  <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      <i className="pi-school-line"></i>
                                  </div>
                                  <div className='kui-modal-box-list-item__container'>
                                      <strong>{cycleI?.intitule}</strong>
                                      <span>{cycleI.finalite}</span>
                                      <button type='button' className='kui-link' onClick={() => {
                                          setCycle("")
                                          setCycleI(null)
                                      }}>
                                          <span>Changer</span>
                                      </button>
                                  </div>
                              </div>
                            </>
                        ) : (
                          <>
                            <div className='kui-modal-box-group__header'><h3>Choix du cycle</h3></div>
                            <div className='kui-modal-box-list'>
                                <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                    <div className='kui-modal-box-list-action-search'>
                                      <i className="pi-search-line"></i>
                                      <input type="search" value={search} placeholder='Recherche...' onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                              {
                                search.trim().length > 2 ? (
                                  cycles
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                  .length > 0 ? (
                                      <div className='kui-modal-box-list__container'>
                                      {
                                          cycles
                                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                          .slice(0, 8)
                                          .map((itemo: any, index: number) => (
                                          <button key={index} type='button' className={`kui-modal-box-list-item ${cycle === itemo?.id ? "active" : ""}`} onClick={() => {
                                              setCycle(itemo?.id)
                                              setCycleI(itemo)
                                          }}>
                                              {
                                                  cycle === itemo?.id ? (
                                                    <div className='kui-modal-box-list-item__check'>
                                                      <i className="pi-minimize-line"></i>
                                                    </div>
                                                  ) : null
                                              }
                                              <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                  <i className="pi-school-line"></i>
                                              </div>
                                              <div className='kui-modal-box-list-item__container'>
                                                  <span>Code: {itemo?.slug}</span>
                                                  <strong>{itemo?.intitule}</strong>
                                                  <span>{itemo?.finalite}</span>
                                              </div>
                                          </button>
                                          ))
                                      }
                                      
                                      </div>
                                  ) : (
                                    <span>Aucun cycle trouvé</span>
                                  )
                                ) : (
                                  <span>Rechercher...</span>
                                )
                              }
                            </div>
                          </>
                        )
                    }
                    {
                        department ? (
                            <>
                              <div className={`kui-modal-box-list-item`}>
                                  <div className='kui-modal-box-list-item__avatar is--rounded'>
                                      <i className="pi-presentation-3-line"></i>
                                  </div>
                                  <div className='kui-modal-box-list-item__container'>
                                      <strong>{departmentI?.intitule}</strong>
                                      <span>{departmentI.nom_court}</span>
                                      <button type='button' className='kui-link' onClick={() => {
                                          setDepartment("")
                                          setDepartmentI(null)
                                      }}>
                                          <span>Changer</span>
                                      </button>
                                  </div>
                              </div>
                            </>
                        ) : (
                          <>
                            <div className='kui-modal-box-group__header'><h3>Choix du departement</h3></div>
                            <div className='kui-modal-box-list'>
                                <div className='kui-modal-box-list__action kui-modal-box-list-action'>
                                    <div className='kui-modal-box-list-action-search'>
                                      <i className="pi-search-line"></i>
                                      <input type="search" value={searchD} placeholder='Recherche...' onChange={(e) => setSearchD(e.target.value)}/>
                                    </div>
                                </div>
                              {
                                searchD.trim().length > 2 ? (
                                  departments
                                  .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                                  .length > 0 ? (
                                      <div className='kui-modal-box-list__container'>
                                      {
                                          departments
                                          .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(searchD?.toLocaleLowerCase()) )
                                          .slice(0, 8)
                                          .map((itemo: any, index: number) => (
                                          <button key={index} type='button' className={`kui-modal-box-list-item ${department === itemo?.id ? "active" : ""}`} onClick={() => {
                                              setDepartment(itemo?.id)
                                              setDepartmentI(itemo)
                                          }}>
                                              {
                                                  department === itemo?.id ? (
                                                    <div className='kui-modal-box-list-item__check'>
                                                      <i className="pi-minimize-line"></i>
                                                    </div>
                                                  ) : null
                                              }
                                              <div className='kui-modal-box-list-item__avatar is--rounded'>
                                                  <i className="pi-presentation-3-line"></i>
                                              </div>
                                              <div className='kui-modal-box-list-item__container'>
                                                  <span>Code: {itemo?.slug}</span>
                                                  <strong>{itemo?.intitule}</strong>
                                                  <span>{itemo?.nom_court}</span>
                                              </div>
                                          </button>
                                          ))
                                      }
                                      
                                      </div>
                                  ) : (
                                    <span>Aucun departement trouvé</span>
                                  )
                                ) : (
                                  <span>Rechercher...</span>
                                )
                              }
                            </div>
                          </>
                        )
                    }

                              
                    <ModalForm tabsField={[
                    {
                        field: "textfield",
                        field_type: "text",
                        field_require: true,
                        value: data.intitule,
                        label: "Intitulé",
                        id: "intitule",
                        onChange: (val: any) => setData({...data, intitule: val}),
                        error: error,
                        placeholder: "Ex: Science de la santé",
                        isShow: true
                    },
                    {
                        field: "textfield",
                        field_type: "text",
                        field_require: true,
                        value: data.nom_court,
                        label: "Nom court",
                        id: "nom_court",
                        onChange: (val: any) => setData({...data, nom_court: val}),
                        error: error,
                        placeholder: "Ex: SDS",
                        isShow: true
                    },
                    {
                        field: "textfield",
                        field_type: "textarea",
                        field_require: false,
                        value: data.description,
                        label: "Description",
                        id: "description",
                        onChange: (val: any) => setData({...data, description: val}),
                        error: error,
                        placeholder: "Décrire un peu la filière",
                        isShow: true
                    },
                    ]}/>
                </div>
                    
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isCreateLoading ? "pi-spinner5" : "pi-save-2-line", 
              type: "submit", 
              label: isCreateLoading ? "Création en cours" : "Enregistrer et ajouter", 
              style:"is--primary", 
              active: !isCreateLoading,
              show:  (closeM && isCreateSuccess ? false : true)
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isCreateSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isCreateLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}
  
export const EditModal = ({item, fnc}:{item: any, fnc: (val: boolean) => void | any}) => {
    const [data, setData] = useState<any>({
        intitule: item.intitule || "",
        nom_court: item.nom_court || "",
        description: item.description || "",
        statut: item.statut || "",
    })
    const dispatch = useAppDispatch();
    const { isEditSuccess, isEditLoading, isEditError, message } = useAppSelector((state) => state.filiere);
  
    const [closeM, setCloseM] = useState<boolean>(false)
    const [error, setError] = useState<any>(null)
  
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      
      if(!data.intitule){
        setError({
            field: "intitule",
            message: "Veuillez renseigner ce champs"
        })
        return
      }
      if(!data.nom_court){
          setError({
              field: "nom_court",
              message: "Veuillez renseigner ce champs"
          })
          return
      }
    
      setError(null)
      setCloseM(true)
      dispatch(updateFiliere({
        data, 
        id: item.id
      }))
    }
  
    const closeModal = () => {
      setData({
        intitule:  "",
        finalite:  "",
        description: "",
        statut: "",
      })
      fnc(false)
      setCloseM(false)
    }
  
    return (
      <Modal size={'is--md'} withClose={!isEditLoading} id={'edit-filier'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
            <h2>Modification d'une filiere</h2>
            <button type='button' className={`kui-chip ${data?.statut === "actif" ? "is--success" : "is--warning"}`} onClick={() => {
                data?.statut === "actif" ? setData({...data, statut: "inactif"}) : setData({...data, statut: "actif"})
            }}>
                {data?.statut === "actif" ? "Actif: Rendre inactif" : "Inactif: Rendre actif"}
            </button>
        </div>
        {
          closeM && isEditSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Modification réussie avec succès</h1>
                  <p> Les modifications ont été enregistrées.</p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              {
                closeM && isEditError ? (
                  <KAlert status={"st--danger"} title={"Ouppss!!!"} message={message}/>
                ) : null
              }
              <ModalForm tabsField={[
                {
                  field: "textfield",
                  field_type: "text",
                  field_require: true,
                  value: data.intitule,
                  label: "Intitulé",
                  id: "intitule",
                  onChange: (val: any) => setData({...data, intitule: val}),
                  error: error,
                  placeholder: "Ex: Science des Données",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "text",
                  field_require: true,
                  value: data.nom_court,
                  label: "Nom court",
                  id: "nom_court",
                  onChange: (val: any) => setData({...data, nom_court: val}),
                  error: error,
                  placeholder: "Ex: SDD",
                  isShow: true
                },
                {
                  field: "textfield",
                  field_type: "textarea",
                  field_require: false,
                  value: data.description,
                  label: "Description",
                  id: "description",
                  onChange: (val: any) => setData({...data, description: val}),
                  error: error,
                  placeholder: "Décrire un peu le cycle",
                  isShow: true
                },
              ]}/>
               
            </div>
          )
        }
        <form onSubmit={handleSubmit}>
          <ModalActions tabsActions={[
            {
              icon: isEditLoading ? "pi-spinner5" : "pi-edit-3-line", 
              type: "submit", 
              label: isEditLoading ? "Modification en cours" : "Enregistrer les modifications", 
              style:"is--primary", 
              active: !isEditLoading,
              show: closeM && isEditSuccess ? false : true
            },
            {
              icon: "pi-close-line", 
              type: "button", 
              label: closeM && isEditSuccess ? "Fermer" : "Annuler", 
              style:"is--danger", 
              active: !isEditLoading,
              onClick: () => closeModal(),
              show: true
            },
          ]}/>
        </form>
      </Modal>
    )
}
  
const DeleteModal = ({item, fnc}:{item?: any,fnc: (val: boolean) => void | any}) => {
    const dispatch = useAppDispatch();
    const { isDeleteError, isDeleteLoading, isDeleteSuccess, message } = useAppSelector((state) => state.department);
    
    const [closeM, setCloseM] = useState<boolean>(false)
    const closeModal = () => {
      fnc(false)
      setCloseM(false)
    }
    return (
      <Modal size={'is--sm'} withClose={true} id={'delete-filiere'} isOpen={true} fnc={(val: boolean) => fnc(val)}>
        <div className='kui-modal-box__header'>
          <h2>Suppression d'une filiere</h2>
        </div>
        {
          closeM && isDeleteSuccess ? (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--success'>
                <div className='kui-modal-box-statut__illustration'>
                  <AddSuccessIllu loop={true} autoplay={true} size={{
                    height: 120,
                    width: 120
                  }}/>
                </div>
                <div className='kui-modal-box-statut__container'>
                  <h1>Suppression réussie avec succès</h1>
                  <p>
                    { "l'element a bien été supprimé" }
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className='kui-modal-box__content'>
              <div className='kui-modal-box__status kui-modal-box-statut is--danger'>
                <div className='kui-modal-box-statut__illustration'>
                  {
                    closeM && isDeleteError ? (
                      <FailIllu loop={true} autoplay={true} size={{
                        height: 120,
                        width: 120
                      }}/>
                    ) : (
                      <i className='pi-delete-2-line'></i>
                    )
                  }
                </div>
                <div className='kui-modal-box-statut__container'>
                  {
                    closeM && isDeleteError ? (
                      <>
                        <h1>Une erreur est survenue</h1>
                        <p>{message}</p>
                      </>
                    ) : (
                      <>
                        <h1>Souhaitez-vous supprimer {"cet element"}?</h1>
                        <p>L'action est irreversible</p>
                      </>
                    )
                  }
                </div>
              </div>
            </div>
          )
        }
        
        <ModalActions tabsActions={[
          {
            icon: isDeleteLoading ? "pi-spinner5" : "pi-delete-2-line", 
            type: "button", 
            label: isDeleteLoading ? "Suppression en cours" : (closeM && isDeleteError ? "Réessayer" : "Supprimer"), 
            style:"is--primary", 
            active: !isDeleteLoading,
            onClick: () => {
                setCloseM(true)
                dispatch(deleteFiliere(item.id))
            },
            show: closeM && isDeleteSuccess ? false : true
          },
          {
            icon: "pi-close-line", 
            type: "button", 
            label: isDeleteSuccess && closeM ? "Fermer" : "Annuler", 
            style:"is--neutral", 
            active: !isDeleteLoading,
            onClick: () => closeModal(),
            show: true
          },
        ]}/>
      </Modal>
    )
}
  
export const FilieresPage = () => {

    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
      if(!token){
        navigate(links.login + `?url=${links.filieres}`)
      }
    }, [token, navigate]);

    const dispatch = useAppDispatch();
    const { isLoading, isError, isSuccess, filieres, message } = useAppSelector((state) => state.filiere);
    const { isLoading: isCLoading, isSuccess: isCSuccess, isError: isCError, message: cMessage, cycles } = useAppSelector((state) => state.cycle);
    const { isLoading: isDLoading, isSuccess: isDSuccess, isError: isDError, message: dMessage, departments } = useAppSelector((state) => state.department);
    
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const display = searchParams.get('display')
    const n = searchParams.get('number')
    const q = searchParams.get('q')
    const [itemNumber, setItemNumber] = useState<number>(n ? parseInt(n) : 15)
    const [search, setSearch] = useState<string>(q  || "")
    const [selectItemModal, setSelectItemModal] = useState<any>(null)
    
    const [addModal, setAddModal] = useState<boolean>(false)
    const [editModal, setEditModal] = useState<boolean>(false)
    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    
    const onChangeSearch  = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
        navigate(links.departments+`?display=${display || "list"}&number=${itemNumber}&q=${e.target.value}`)
    }
    
    const onChangeNumber  = (e: React.ChangeEvent<HTMLInputElement>) => {
      setItemNumber(parseInt(e.target.value))
      if(parseInt(e.target.value) > 0){
          navigate(links.departments+`?display=${display || "list"}&number=${parseInt(e.target.value)}&q=${search}`)
      }
    }
    useEffect(() => {
      dispatch(getCycles({}))
      dispatch(getDepartments({}))
      dispatch(getFilieres({}))
      return () => {
        dispatch(reset())
        dispatch(dreset())
        dispatch(freset())
      }
    }, [dispatch])

    return (
        <MainLayout title="Filiere" isMin={true}>
            <div className='kui-page is--min'>
                <div className='kui-arianne'>
                    <Link className='kui-link' to={links.home}>
                        <i className="pi-home-6-line"></i>
                        <span>Accueil</span>
                    </Link>
                    <span> | </span>
                    <p>Filière</p>
                </div>
                {
                    isLoading || isCLoading || isDLoading ? (
                        <div className='kui-loader'>
                            <LoaderAnimation loop={true} autoplay={true} size={{
                                height: 40,
                                width: 40
                            }}/>
                        </div>
                    ) : (
                        <>
                            <div className='kui-page__header kui-page-header'>
                                <h2>Filière</h2>
                            </div>

                            <div className='kui-page-list'>
                                <div className='kui-page-list__actions kui-page-list-actions'>
                                    <div className='kui-page-list-actions-display'>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display ? (display === "list" ? "active" : "") : "active"}`} onClick={() => navigate(links.filieres+`?display=list&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                            <i className="pi-list-check-line"></i>
                                        </button>
                                        <button type='button' className={`kui-page-list-actions-display__btn ${display === "grid" ? "active" : ""}`} onClick={() => navigate(links.filieres+`?display=grid&number=${itemNumber}${search ? `&q=${search}` : ""}`)}>
                                            <i className="pi-layout-grid-line"></i>
                                        </button>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <div className='kui-page-list-actions-input'>
                                            <span>Nombre à afficher: </span>
                                            <input type="number" min={1} max={50}  value={itemNumber} placeholder='15' onChange={onChangeNumber}/>
                                        </div>
                                        <div className='kui-page-list-actions-search'>
                                            <i className="pi-search-line"></i>
                                            <input type="search" value={search} placeholder='Recherche...' onChange={onChangeSearch}/>
                                        </div>
                                    </div>
                                    <div className='kui-page-list-actions-group'>
                                        <Button label={'Ajouter'} iconLeftClass='pi-add-circle-line' btype='button' bstyle='is--primary' onClick={() => setAddModal(true)}/>
                                    </div>
                                </div>
                                {
                                    isCError || isError || isDError ? (
                                        <div className='kui-page-error'>
                                            <div className='kui-page-error__illustration'>
                                                <NotFoundError loop={true} autoplay={true} size={{
                                                    height: 180,
                                                    width: 180
                                                }}/>
                                            </div>
                                            <div className='kui-page-error_details'>
                                                <p>Oupps! Une erreur est survenue</p>
                                                <h1>{isError ? message : isCError ? cMessage : isDError ? dMessage : ""}</h1>
                                            </div>
                                        </div> 
                                    ) : (
                                        isSuccess && isCSuccess && isDSuccess && departments && cycles && filieres ? (
                                            <div className='kui-page-list__container'>
                                                <span className='kui-page-list__total'>
                                                    {
                                                        filieres.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                        .length > 0 ? `
                                                        ${filieres.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                            .length > 1 ? filieres.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                            .length + " elements trouvés" : filieres.filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                            .length +" element trouvé"} ` : "Aucun element trouvé"
                                                    }
                                                </span>

                                                {
                                                    filieres
                                                    .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                    .length > 0 ? (
                                                        display === "grid" ? (
                                                        <Grid direction={'fd-row'} spacing={'jc-start'} align={'start'} wrap={true} gap={1}>
                                                            {
                                                                filieres
                                                                .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) )
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <div className='kui-page-list-item is--md' key={index}>
                                                                        <div className='kui-page-list-item__min'>
                                                                            <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                                {item.statut}
                                                                            </span>
                                                                        </div>
                                                                        

                                                                        <div className='kui-page-list-item__container'>
                                                                            <div className='kui-page-list-item__details'>
                                                                                <strong className='kui-page-list-item__title'>{item?.intitule}</strong>
                                                                                <p>{`Nom court: ${item.nom_court}`}</p>
                                                                                <p>{departments.filter((el: any) => el.id === item.departement)[0]?.intitule || ""} - {(cycles.filter((el: any) => el.id === item.cycle)[0]?.intitule + " " +cycles.filter((el: any) => el.id === item.cycle)[0]?.finalite) || ""}</p>
                                                
                                                                            </div>
                                                                        </div>
                                                                        <div className='kui-page-list-item__actions'>
                                                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                                navigate(links.filiere + "/" + item.id)
                                                                            }}>
                                                                                <i className={"pi-eye-line"}></i>
                                                                                <span>{"Apercu"}</span>
                                                                            </button>
                                                                            <button type='button' className={`kui-table-option is--neutral`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setEditModal(true)
                                                                            }}>
                                                                                <i className={"pi-edit-3-line"}></i>
                                                                                <span>{"Modifier"}</span>
                                                                            </button>
                                                                            <button type='button' className={`kui-table-option is--danger`} onClick={() => {
                                                                                setSelectItemModal(null)
                                                                                setSelectItemModal(item)
                                                                                setDeleteModal(true)
                                                                            }}>
                                                                                <i className={"pi-delete-2-line"}></i>
                                                                                <span>{"Supprimer"}</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                ))        
                                                            }
                                                        </Grid>
                                                        ) : (
                                                        <Table>
                                                            <Row rtype='header'>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Intitulé</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Cycle</strong>
                                                                </Column>
                                                                <Column ctype={'large'}>
                                                                    <strong>Departement</strong>
                                                                </Column>
                                                                <Column ctype={'mid'}>
                                                                    <strong>Statut</strong>
                                                                </Column>
                                                                <Column ctype={'small'}>
                                                                    <strong>#</strong>
                                                                </Column>
                                                            </Row>
                                                            {
                                                                filieres
                                                                .filter((el: any) => el?.intitule?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()) || el?.nom_court?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
                                                                .slice(0, itemNumber)
                                                                .map((item: any, index: number) => (
                                                                    <Row key={index}>
                                                                        <Column ctype={'small'}>
                                                                            <strong>{`#${index + 1}`}</strong>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                            <TableItem title={item?.intitule} subtitle={item.nom_court} withImage={false} image={null}/>
                                                                        </Column>
                                                                        <Column ctype={'mid'}>
                                                                           <div>
                                                                            <span className='kui-tag'>{cycles.filter((el: any) => el.id === item.cycle)[0]?.intitule || ""}</span>
                                                                           </div>
                                                                        </Column>
                                                                        <Column ctype={'large'}>
                                                                           <div>
                                                                            <span className='kui-tag'>{departments.filter((el: any) => el.id === item.departement)[0]?.intitule || ""}</span>
                                                                           </div>
                                                                        </Column>
                                                                        <Column ctype={'mid'}>
                                                                            <div>
                                                                                <span className={`kui-chip ${item?.statut ? "is--success" : "is--warning"}`}>
                                                                                {item.statut}
                                                                                </span>
                                                                            </div>
                                                                        </Column>
                                                                        <Column ctype={'small'}>
                                                                            <TableOptions atype={"reduce"} tabs={[
                                                                            {
                                                                                icon: "pi-eye-line", 
                                                                                label: "Aperçu", 
                                                                                style:"is--neutral", 
                                                                                onClick: () => {
                                                                                    navigate(links.filiere + "/" + item.id)
                                                                                }
                                                                            },
                                                                            {
                                                                                icon: "pi-edit-3-line", 
                                                                                label: "Modifier", 
                                                                                style:"is--neutral", 
                                                                                onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setEditModal(true)
                                                                                }
                                                                            },
                                                                            {
                                                                                icon: "pi-delete-2-line", 
                                                                                label: "Supprimer", 
                                                                                style:"is--danger", 
                                                                                onClick: () => {
                                                                                    setSelectItemModal(null)
                                                                                    setSelectItemModal(item)
                                                                                    setDeleteModal(true)
                                                                                }
                                                                            },
                                                                            ]}/>
                                                                        </Column>
                                                                    </Row>
                                                                ))
                                                            }
                                                        </Table>
                                                        )
                                                    ) : (
                                                        <div className='kui-page-empty'>
                                                            <div className='kui-page-empty__illustration'>
                                                                <EmptyList loop={true} autoplay={true} size={{
                                                                    height: (380 / 1.3),
                                                                    width: (420 / 1.3)
                                                                }}/>
                                                            </div>
                                                            <div className='kui-page-empty__details'>
                                                                <h1>Aucun element trouvé</h1>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        ) : null
                                    )
                                }
                            </div>

                            {addModal ? <AddModal cycles={cycles} departments={departments} fnc={(val?: boolean) => setAddModal(val || false)}/> : null}
                            {editModal && selectItemModal  ? <EditModal item={selectItemModal} fnc={(val?: boolean) => setEditModal(val || false)}/> : null}
                            {deleteModal && selectItemModal ? <DeleteModal item={selectItemModal} fnc={(val?: boolean) => setDeleteModal(val || false)} /> : null}
                        </>
                    )
                }
            </div>
        </MainLayout>
    )
}
