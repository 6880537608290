import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { UniteEnseignementState } from '../../interface/GeneralStateInterface';

const URI = API_URL+"gestiondescursus/unites_enseignements/"
const URI2 = API_URL+"gestiondescursus/unite_enseignements_semestre_niveau/"

const initialState: UniteEnseignementState = {
    unites_enseignements: [],
    semester_unites_enseignements: [],
    unite_enseignement: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setUniteEnseignement = createAsyncThunk<any, Object>(
    'unite-enseignement/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getUniteEnseignements = createAsyncThunk<any, Object>(
    'unite-enseignement/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getLinkBetweenUniteEnseignementsSemester = createAsyncThunk<any, Object>(
    'unite-enseignement/links-with-semester',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getUniteEnseignement = createAsyncThunk<any, Object>(
    'unite-enseignement/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateUniteEnseignement = createAsyncThunk<any, Object>(
    'unite-enseignement/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const linkUniteEnseignementToSemestre = createAsyncThunk<any, Object>(
    'unite-enseignement/link-to-semester',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}`,
                content: content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteUniteEnseignement = createAsyncThunk<any, Object>(
    'unite-enseignement/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const unlinkUniteEnseignementToSemestre = createAsyncThunk<any, Object>(
    'unite-enseignement/unlink-to-semester',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI2}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const uniteEnseignementSlice = createSlice({
    name: 'unite-enseignement',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUniteEnseignements.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUniteEnseignements.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.unites_enseignements = action.payload
            })
            .addCase(getUniteEnseignements.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getLinkBetweenUniteEnseignementsSemester.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getLinkBetweenUniteEnseignementsSemester.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.semester_unites_enseignements = action.payload
            })
            .addCase(getLinkBetweenUniteEnseignementsSemester.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getUniteEnseignement.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUniteEnseignement.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.unite_enseignement = action.payload
            })
            .addCase(getUniteEnseignement.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setUniteEnseignement.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setUniteEnseignement.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.unites_enseignements = [action.payload, ...state.unites_enseignements]
            })
            .addCase(setUniteEnseignement.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(linkUniteEnseignementToSemestre.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(linkUniteEnseignementToSemestre.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.semester_unites_enseignements = [action.payload, ...state.semester_unites_enseignements]
            })
            .addCase(linkUniteEnseignementToSemestre.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateUniteEnseignement.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateUniteEnseignement.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.unites_enseignements = [action.payload, ...state.unites_enseignements.filter((unite_enseignement: any) => unite_enseignement?.id !== action.payload?.id)]
                state.unite_enseignement = action.payload
            })
            .addCase(updateUniteEnseignement.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteUniteEnseignement.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteUniteEnseignement.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.unites_enseignements = state.unites_enseignements.filter((unite_enseignement: any) => unite_enseignement?.id !== action.payload?.id)
            })
            .addCase(deleteUniteEnseignement.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

            .addCase(unlinkUniteEnseignementToSemestre.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(unlinkUniteEnseignementToSemestre.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.semester_unites_enseignements = state.semester_unites_enseignements.filter((unite_enseignement: any) => unite_enseignement?.id !== action.payload?.id)
            })
            .addCase(unlinkUniteEnseignementToSemestre.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = uniteEnseignementSlice.actions
export default uniteEnseignementSlice.reducer