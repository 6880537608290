import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CycleState } from '../../interface/GeneralStateInterface';
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';

const URI = API_URL+"gestiondescursus/cycles/"

const initialState: CycleState = {
    cycles: [],
    cycle: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isCreateError: false,
    isCreateSuccess: false,
    isCreateLoading: false,

    isEditError: false,
    isEditSuccess: false,
    isEditLoading: false,

    isDeleteError: false,
    isDeleteSuccess: false,
    isDeleteLoading: false,

    message: null,
}


export const setCycle = createAsyncThunk<any, Object>(
    'cycle/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                content,
                token: tokens
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const getCycles = createAsyncThunk<any, Object>(
    'cycle/list',
    async (_, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)
export const getCycle = createAsyncThunk<any, Object>(
    'cycle/show',
    async (slug: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${slug}/`,
                token: tokens
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message.message)
        }
    }
)

export const updateCycle = createAsyncThunk<any, Object>(
    'cycle/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content.id}/`,
                content: content.data,
                token: tokens
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteCycle = createAsyncThunk<any, Object>(
    'cycle/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token

            let data = {
                url: `${URI}${content}/`,
                content,
                token: tokens
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            console.log(error)
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const cycleSlice = createSlice({
    name: 'cycle',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCycles.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCycles.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.cycles = action.payload
            })
            .addCase(getCycles.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getCycle.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getCycle.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.cycle = action.payload
            })
            .addCase(getCycle.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setCycle.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setCycle.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.cycles = [action.payload, ...state.cycles]
            })
            .addCase(setCycle.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateCycle.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateCycle.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.cycles = [action.payload, ...state.cycles.filter((cycle: any) => cycle?.id !== action.payload?.id)]
                state.cycle = action.payload
            })
            .addCase(updateCycle.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteCycle.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteCycle.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.cycles = state.cycles.filter((cycle: any) => cycle?.id !== action.payload?.id)
            })
            .addCase(deleteCycle.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = cycleSlice.actions
export default cycleSlice.reducer