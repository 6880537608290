import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { API_URL } from '../../../utils';
import { deleteProtecteItems, getProtecteItems, setProtecteItems, updateProtecteItems } from '../../../utils/src/function';
import { SchoolYearState } from '../../interface/GeneralStateInterface';

const AUTHURI = API_URL+"gestiondesanneesscolaires"

const initialState: SchoolYearState = {
    school_years: [],
    school_year: null,
    subscription_sessions: [],
    subscription_session: null,

    isError: false,
    isLoading: false,
    isSuccess: false,

    isEditError: false,
    isEditLoading: false,
    isEditSuccess: false,

    isCreateError: false,
    isCreateLoading: false,
    isCreateSuccess: false,

    isDeleteError: false,
    isDeleteLoading: false,
    isDeleteSuccess: false,

    message: ""
}


export const getSchoolYears = createAsyncThunk<any, Object>(
    'school-year/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getSchoolYear = createAsyncThunk<any, Object>(
    'school-year/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/${content}/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const setSchoolYear = createAsyncThunk<any, Object>(
    'school-year/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const addFiliereSchoolYear = createAsyncThunk<any, Object>(
    'school-year/add-filiere',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/${content.id}/ajouter_filiere/`,
                token: tokens,
                content: content.data
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const removeFiliereSchoolYear = createAsyncThunk<any, Object>(
    'school-year/remove-filiere',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/${content.id}/retirer_filiere/`,
                token: tokens,
                content: content.data
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateSchoolYear = createAsyncThunk<any, Object>(
    'school-year/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/${content.id}`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteSchoolYear = createAsyncThunk<any, Object>(
    'school-year/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/annees_scolaires/${content}/`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)


export const getSubscriptionYears = createAsyncThunk<any, Object>(
    'subscription-year/list',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/sessions_inscriptions/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const getSubscriptionYear = createAsyncThunk<any, Object>(
    'subscription-year/show',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/sessions_inscriptions/${content}/`,
                token: tokens,
                content
            }
            return await getProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const setSubscriptionYear = createAsyncThunk<any, Object>(
    'subscription-year/create',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/sessions_inscriptions/`,
                token: tokens,
                content
            }
            return await setProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const updateSubscriptionYear = createAsyncThunk<any, Object>(
    'subscription-year/update',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/sessions_inscriptions/${content.id}`,
                token: tokens,
                content: content.data
            }
            return await updateProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const deleteSubscriptionYear = createAsyncThunk<any, Object>(
    'subscription-year/delete',
    async (content: any, thunkAPI) => {
        try {
            // @ts-ignore
            const tokens = thunkAPI.getState().auth.token
            let data = {
                url: `${AUTHURI}/sessions_inscriptions/${content}/`,
                token: tokens,
                content
            }
            return await deleteProtecteItems(data)
        } catch (error:any) {
            return thunkAPI.rejectWithValue(error.response.data.message)
        }
    }
)

export const schoolYearSlice = createSlice({
    name: 'school_year_gestion',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isError = false
            state.isSuccess = false

            state.isCreateLoading = false
            state.isCreateError = false
            state.isCreateSuccess = false

            state.isEditLoading = false
            state.isEditError = false
            state.isEditSuccess = false

            state.isDeleteLoading = false
            state.isDeleteError = false
            state.isDeleteSuccess = false

            state.message = null
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(getSchoolYears.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSchoolYears.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.school_years = action.payload
            })
            .addCase(getSchoolYears.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSubscriptionYears.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubscriptionYears.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.subscription_sessions = action.payload
            })
            .addCase(getSubscriptionYears.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSchoolYear.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSchoolYear.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.school_year = action.payload
            })
            .addCase(getSchoolYear.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(getSubscriptionYear.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getSubscriptionYear.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.subscription_session = action.payload
            })
            .addCase(getSubscriptionYear.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })

            .addCase(setSchoolYear.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setSchoolYear.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.school_years = [action.payload, ...state.school_year]
            })
            .addCase(setSchoolYear.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(setSubscriptionYear.pending, (state) => {
                state.isCreateLoading = true
            })
            .addCase(setSubscriptionYear.fulfilled, (state, action) => {
                state.isCreateLoading = false
                state.isCreateSuccess = true
                state.subscription_sessions = [action.payload, ...state.subscription_sessions]
            })
            .addCase(setSubscriptionYear.rejected, (state, action) => {
                state.isCreateLoading = false
                state.isCreateError = true
                state.message = action.payload
            })

            .addCase(updateSchoolYear.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateSchoolYear.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.school_years = [action.payload, ...state.school_years.filter((acte: any) => acte?.id !== action.payload?.id)]
                state.school_year = action.payload
            })
            .addCase(updateSchoolYear.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(addFiliereSchoolYear.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(addFiliereSchoolYear.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                // state.school_years = [action.payload, ...state.school_years.filter((acte: any) => acte?.id !== action.payload?.id)]
                // state.school_year = action.payload
            })
            .addCase(addFiliereSchoolYear.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(updateSubscriptionYear.pending, (state) => {
                state.isEditLoading = true
            })
            .addCase(updateSubscriptionYear.fulfilled, (state, action) => {
                state.isEditLoading = false
                state.isEditSuccess = true
                state.subscription_sessions = [action.payload, ...state.subscription_session.filter((acte: any) => acte?.id !== action.payload?.id)]
                state.subscription_session = action.payload
            })
            .addCase(updateSubscriptionYear.rejected, (state, action) => {
                state.isEditLoading = false
                state.isEditError = true
                state.message = action.payload
            })

            .addCase(deleteSchoolYear.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteSchoolYear.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.school_years = state.school_years.filter((acte: any) => acte?.id !== action.payload?.id)
            })
            .addCase(deleteSchoolYear.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

            .addCase(removeFiliereSchoolYear.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(removeFiliereSchoolYear.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                // state.school_years = state.school_years.filter((acte: any) => acte?.id !== action.payload?.id)
            })
            .addCase(removeFiliereSchoolYear.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })

            .addCase(deleteSubscriptionYear.pending, (state) => {
                state.isDeleteLoading = true
            })
            .addCase(deleteSubscriptionYear.fulfilled, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteSuccess = true
                state.subscription_sessions = state.subscription_sessions.filter((acte: any) => acte?.id !== action.payload?.id)
            })
            .addCase(deleteSubscriptionYear.rejected, (state, action) => {
                state.isDeleteLoading = false
                state.isDeleteError = true
                state.message = action.payload
            })
    }
})

export const { reset } = schoolYearSlice.actions
export default schoolYearSlice.reducer