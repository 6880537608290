import React, { useContext, useEffect } from 'react'
import { UserAuthContext } from '../../../../context/AuthContext';
import { links } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from '../../../layout';

export const HomePage = () => {
    const { token } = useContext(UserAuthContext);
    let navigate = useNavigate()

    useEffect(() => {
        if(!token){
            navigate(links.login + `?url=${links.home}`)
        }
    }, [token, navigate]);

    return (
        <MainLayout>
          <h1>ok</h1>
        </MainLayout>
    )
}
