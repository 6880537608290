import React from 'react'
import { MainLayout } from '../../../layout'
import { NotFoundError } from '../../../assets/illustration'

export const ErrorPage = () => {
  return (
    <MainLayout title='Page introuvable'>
      <div className='kui-page-error'>
        <div className='kui-page-error__illustration'>
          <NotFoundError loop={true} autoplay={true} size={{
            height: 180,
            width: 180
          }}/>
        </div>
        <div className='kui-page-error_details'>
          <p>Erreur 404</p>
          <h1>Page introuvable...</h1>
        </div>
      </div>
    </MainLayout>
  )
}
